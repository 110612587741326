import { gql } from 'src/__generated__'
import { CanteensQueryQuery } from 'src/__generated__/graphql'

export type Canteen = CanteensQueryQuery['canteens'][0]

export default gql(`
  query canteensQuery($slug: String, $offset: Int, $limit: Int) {
    canteens(query: {slug: $slug, offset: $offset, limit: $limit}) {
      id
      name
      region
      city
      canteenSystemType
      externalSystemId
    }
  }
`)
