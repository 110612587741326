import styled from 'styled-components'
import Button from 'src/components/Button'

interface Props {
  setStep: React.Dispatch<React.SetStateAction<number>>
}

const SelectAction = (props: Props) => {
  return (
    <>
      <h2>Upravit</h2>

      <ButtonsBox>
        <Button>Navrhnout podobné jídlo</Button>
        <Button>Navrhnout podobné jídlo z jiné jídelny</Button>
        <Button onClick={() => props.setStep(1)}>Navrhnout podobné jídlo AI</Button>
        <Button>Vyměnit za jiné</Button>
      </ButtonsBox>
    </>
  )
}

export default SelectAction

const ButtonsBox = styled.div`
  text-align: center;
  width: fit-content;
  margin: 0 auto;
`
