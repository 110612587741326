import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import Main from './Main'
import useLogin from '../../hooks/useLogin'
import { routes } from 'src/constants/routes'

const EditUser = () => {
  const loginData = useLogin()
  const { id } = useParams<{ id?: string }>()

  const userId = (id ? parseInt(id) : undefined) ?? loginData.user?.id

  if (!loginData.user || !userId) {
    return <Navigate to={routes.login} />
  }

  return <Main userId={userId} />
}

export default EditUser
