import React from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { Navigate } from 'react-router-dom'

import LoaderSpinner from '../../layout/loader'
import photos, { Photo } from '../../queries/v2/photos'
import Food from './Food'
import { navy } from 'src/layout/colors'

const Main = () => {
  const { loading, data } = useQuery(photos, {
    variables: {
      approved: false,
    },
  })

  if (loading) return <LoaderSpinner />
  if (!data && !loading) return <Navigate to={'/'} />

  const reducePhotos = data?.photos.reduce(
    (acc, item) => {
      const foodId = item.food?.id ?? ''
      return {
        ...acc,
        [foodId]: {
          food: item.food,
          photos: [...(acc[foodId]?.photos ?? []), item],
        },
      }
    },
    {} as { [key: string]: { food: Photo['food']; photos: Photo[] } },
  )

  return (
    <>
      <Container100vh>
        <Container>
          <Inner>
            <h1>Nové fotografie</h1>
            <ContentContainer>
              {Object.values(reducePhotos ?? {})?.map((item) => {
                return <Food foodName={item.food?.name ?? ''} item={item.photos} />
              })}
            </ContentContainer>
          </Inner>
        </Container>
      </Container100vh>
    </>
  )
}

export default Main

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  background: url('/img/pattern_modry.svg'), ${navy};
  background-attachment: fixed;
  background-position: center;
  background-size: 730px;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  height: 100%;
`

const Inner = styled.div`
  text-align: center;
  background-color: white;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  width: 80%;
  margin: 10px auto;
  border-radius: 5px;
  padding: 20px 20px;
`

const ContentContainer = styled.div``
