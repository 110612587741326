import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'

import { navy } from '../../layout/colors'
import TextInput from '../../layout/textInput'
import FormInfoText from '../../layout/FormInfoText'
import login from '../../mutations/v2/login'
import { Link } from 'react-router-dom'
import { routes } from 'src/constants/routes'
import { saveLoginInfo } from 'src/hooks/useLogin'
import Button from 'src/components/Button'

const Main = () => {
  const ref = useRef<HTMLButtonElement>(null)

  const [formState, setFormState] = useState({
    email: '',
    password: '',
  })

  const [logIn, { error }] = useMutation(login, {
    onCompleted({ login }) {
      saveLoginInfo(login)
      window.location.replace(routes.main)
    },
  })

  function handleSubmit() {
    const email = formState.email
    const password = formState.password
    return logIn({ variables: { email, password } })
  }

  function handleEnter(event: any) {
    if (event.keyCode === 13 && ref && ref.current) {
      event.preventDefault()
      ref.current.click()
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', handleEnter)

    return () => {
      window.removeEventListener('keyup', handleEnter)
    }
  }, [])

  return (
    <>
      <Container100vh>
        <Container>
          <LoginForm>
            <Heading>Přihlášení</Heading>
            <TextInput
              type="email"
              placeholder="Váš email"
              name="email"
              value={formState.email}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  email: e.target.value,
                })
              }
            />
            <TextInput
              type="password"
              placeholder="Heslo"
              value={formState.password}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  password: e.target.value,
                })
              }
            />
            <div>{error && <LoginError>Nesprávné přihlašovací údaje</LoginError>}</div>
            <Button ref={ref} onClick={handleSubmit} type="submit">
              Přihlásit se
            </Button>
            <p style={{ textAlign: 'center' }}>
              Ještě nemáte účet? Zaregistrujte se <NavLink to={routes.register}>zde</NavLink>
            </p>
            <FormInfoText linkText={'Zapoměli jste heslo?'} href={routes.forgotPassword} />
          </LoginForm>
        </Container>
      </Container100vh>
    </>
  )
}

export default Main

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;

  background: url('/img/pattern_modry.svg'), ${navy};
  background-attachment: fixed;
  background-position: center;
  background-size: 730px;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  padding: 30px 0;
  height: 100%;
`

const LoginForm = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 50px 20px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  background-color: white;

  @media (min-width: 500px) {
    padding: 20px 50px 50px 50px;
    width: 70%;
  }

  @media (min-width: 600px) {
    width: 50%;
  }

  @media (min-width: 900px) {
    width: 30%;
  }

  @media (min-width: 2000px) {
    width: 20%;
  }

  @media (min-width: 3000px) {
    width: 15%;
  }
`
const Heading = styled.h2`
  text-align: center;
`

const LoginError = styled.span`
  color: #fc6969;
  font-weight: 500;
  font-size: 0.81em;
  margin-bottom: 1.75em;
  margin-top: -0.8em;
  display: flex;
  justify-content: flex-start;
`

const NavLink = styled(Link)`
  color: ${navy};
`
