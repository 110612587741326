import React from 'react'
import styled from 'styled-components'
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material'
import { Box } from '@mui/material'

import { useConfirm } from 'material-ui-confirm'

import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router'

import users, { User } from '../../queries/v2/users'
import { backgroundGray, navy } from '../../layout/colors'
import removeUser from '../../mutations/v2/removeUser'
import { routes } from 'src/constants/routes'
import { IconButton } from 'src/components/Button'

interface Props {
  item: User
}

const Main = ({ item }: Props) => {
  const confirm = useConfirm()
  const navigate = useNavigate()
  const rights = ['ban', 'uživatel', 'správce jídelny', '', 'admin']

  const refetchQueries = { refetchQueries: [{ query: users }] }
  const [req] = useMutation(removeUser, {
    ...refetchQueries,
  })

  function handleDelete(userId: number, data: { email: string }) {
    confirm({
      title: `Smazat uživatele ${data.email}?`,
      description: `Tato akce je nevratná.`,
      cancellationText: 'Zrušit',
    }).then(() => {
      req({ variables: { id: userId } })
    })
  }

  return (
    <Row>
      <Td>{item.id}</Td>
      <Td>{rights[item.adminLevel]}</Td>
      <Td>{item.userName}</Td>
      <Td>
        <Box display="flex" gap={2}>
          {item.email}{' '}
          {item.isEmailVerified ? (
            <CheckCircleIcon color="success" fontSize="small" />
          ) : (
            <CancelIcon color="error" fontSize="small" />
          )}
        </Box>
      </Td>
      <Td>{item.canteen?.name}</Td>
      <Td>
        <IconButton onClick={() => navigate(routes.usersDetailEdit(item.id!))}>
          <Edit />
        </IconButton>
      </Td>
      <Td>
        <IconButton
          onClick={() => {
            handleDelete(item.id, { email: item.email })
          }}
        >
          <Delete />
        </IconButton>
      </Td>
    </Row>
  )
}

export default Main

const Row = styled.tr`
  &:nth-child(odd) {
    background-color: ${backgroundGray};
  }

  &:hover {
    background-color: #e9e9e9;
  }
`

const Td = styled.td`
  padding: 5px;

  &:nth-last-child(1) {
    text-align: right;
    width: 34px;
  }

  &:nth-last-child(2) {
    text-align: right;
    width: 34px;
  }
`

const Edit = styled(EditIcon)`
  color: ${navy};
`

const Delete = styled(DeleteIcon)`
  color: ${navy};
`
