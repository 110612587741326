import { gql } from 'src/__generated__'
import { CanteenOffersQueryQuery } from 'src/__generated__/graphql'

export type CanteenOffer = CanteenOffersQueryQuery['canteenOffers'][0]

export default gql(`
  query canteenOffersQuery($query: GetOffersInput!) {
    canteenOffers(query: $query) {
      id
      date
      food {
        id
        name
        averageRating
      }
    }
  }
`)
