import { gql } from 'src/__generated__'
import { LoginResponse } from 'src/__generated__/graphql'

export type { LoginResponse }

export default gql(`
  mutation register($data: RegisterInput!) {
    register(data: $data) {
      token
      user {
        id
        email
        userName
        adminLevel
        canteen {
          id
        }
      }
    }
  }
`)
