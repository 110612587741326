import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'

import LoaderSpinner from '../../layout/loader'
import Food from './Food'
import { backgroundGray, navy } from '../../layout/colors'
import foods from '../../queries/v2/foods'
import Search from 'src/layout/Search'
import { ButtonLink } from 'src/components/Button'
import { routes } from 'src/constants/routes'

type Props = {
  canteenId: number
}

const Main: React.FC<Props> = ({ canteenId }) => {
  const [searchValue, setSearchValue] = useState('')
  const { loading, data, refetch } = useQuery(foods, {
    variables: { canteenId, search: searchValue },
  })

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchValue.length >= 3 || searchValue === '') {
        refetch({
          canteenId: canteenId,
          search: searchValue,
        })
      }
    }, 500)

    return () => clearTimeout(timeout)
  }, [searchValue])

  if (loading) return <LoaderSpinner />

  return (
    <>
      <Container100vh>
        <Container>
          <Inner>
            <h1>Administrace pokrmů</h1>
            <SearchContainer>
              <SearchFood
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                clearSearch={() => setSearchValue('')}
                placeholder={`např.: ${data?.foods?.[0] ? data.foods[0].name : 'Guláš'}`}
              />
            </SearchContainer>

            <br />
            <br />

            <ButtonLink to={routes.canteenDetailFoodDuplicates(canteenId)}>
              Kontrola duplicit
            </ButtonLink>

            <TableContainer>
              <Table>
                <TableHead>
                  <Th>Název</Th>
                  <Th></Th>
                  <Th></Th>
                  <Th></Th>
                </TableHead>
                {data?.foods.length === 0 &&
                  (searchValue
                    ? 'Žádné jídlo nebylo nalezeno'
                    : 'Zatím nejsou vytvořena žádná jídla')}
                {data?.foods.map((item) => {
                  return <Food item={item} canteenId={canteenId} />
                })}
              </Table>
            </TableContainer>
          </Inner>
        </Container>
      </Container100vh>
    </>
  )
}

export default Main

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  background: url('/img/pattern_modry.svg'), ${navy};
  background-attachment: fixed;
  background-position: center;
  background-size: 730px;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  height: 100%;
`

const Table = styled.table`
  text-align: left;
  background-color: white;
  border-collapse: collapse;
  width: 100%;
`

const Inner = styled.div`
  text-align: center;
  background-color: white;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  width: 80%;
  margin: 10px auto;
  border-radius: 5px;
  padding: 20px 20px;
`

const TableContainer = styled.div`
  overflow-x: scroll;
  margin-top: 32px;
`

const TableHead = styled.tr`
  background-color: ${backgroundGray};
`
const Th = styled.th`
  padding: 10px;
  color: white;
  background-color: ${navy};
`

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
`

const SearchFood = styled(Search)`
  margin: 0;
`
