import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'

import LoaderSpinner from '../../layout/loader'
import comments from 'src/queries/v2/comments'
import { Grid } from '@mui/material'
import Comment from '../food/Comment'
import useLogin from 'src/hooks/useLogin'
import { UserAdminLevel } from 'src/constants/constants'
import { navy } from 'src/layout/colors'

type Props = {
  canteenId: number
}

const Main: React.FC<Props> = ({ canteenId }) => {
  const user = useLogin()
  const [searchValue, setSearchValue] = useState('')
  const { loading, data, refetch } = useQuery(comments, {
    variables: { query: { canteenId } },
  })

  if (
    !(
      user.user?.adminLevel === UserAdminLevel.SUPERADMIN ||
      (user.user?.adminLevel === UserAdminLevel.ADMIN && user.user?.canteenId === canteenId)
    )
  ) {
    return null
  }

  if (loading) return <LoaderSpinner />

  return (
    <>
      <Container100vh>
        <Container>
          <Inner>
            <h1>Hodnocení</h1>
            <Grid container spacing={2} justifyContent="center">
              {data?.comments.length === 0 && <p>Žádná zatím nebyla vyplněna</p>}
              {data?.comments?.map((comment) => (
                <Grid item key={comment.id} xs={12} md={6} lg={4}>
                  <Comment
                    comment={comment}
                    foodId={undefined}
                    canteenId={canteenId}
                    userCanEditComment={!!user.SUPERADMIN}
                    showFoodName={true}
                  />
                </Grid>
              ))}
            </Grid>
          </Inner>
        </Container>
      </Container100vh>
    </>
  )
}

export default Main

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  background: url('/img/pattern_modry.svg'), ${navy};
  background-attachment: fixed;
  background-position: center;
  background-size: 730px;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  height: 100%;
`

const Inner = styled.div`
  text-align: center;
  background-color: white;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  width: 80%;
  margin: 10px auto;
  border-radius: 5px;
  padding: 20px 20px;
`
