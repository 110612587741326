import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useMutation, useQuery } from '@apollo/client'
import { Rating } from 'react-simple-star-rating'
import { Alert } from '@mui/material'

import { TextArea } from '../../layout/textInput'
import { InputValueStateType } from '../../constants/types'
import useLogin from '../../hooks/useLogin'
import createComment from '../../mutations/v2/createComment'
import comments from '../../queries/v2/comments'
import { Link } from 'react-router-dom'
import { CreateCommentInput } from 'src/__generated__/graphql'
import { routes } from 'src/constants/routes'
import Button from 'src/components/Button'
import userQuery from 'src/queries/v2/user'

interface Props {
  foodId: number
}

const AddComment = ({ foodId }: Props) => {
  const { user } = useLogin()
  const ref = useRef<HTMLButtonElement>(null)
  const [submitButtonInactive, setSubmitButtonInactive] = useState(true)
  const [err, setError] = useState(false)
  const refetchQueries = {
    refetchQueries: [
      {
        query: comments,
        variables: { query: { foodId: foodId } },
      },
    ],
  }

  const userId = user?.id
  const userData = useQuery(userQuery, {
    variables: { userId: userId! },
  })

  const [formState, setFormState] = useState<
    InputValueStateType<'text' | 'rating_taste' | 'rating_visual' | 'rating_preparation'>
  >({
    text: { value: '', isInvalid: false },
    rating_taste: { value: '0', isInvalid: false },
    rating_visual: { value: '0', isInvalid: false },
    rating_preparation: { value: '0', isInvalid: false },
  })

  const [reg] = useMutation(createComment, {
    onCompleted(comment) {
      setFormState({
        text: { value: '', isInvalid: false },
        rating_taste: { value: '0', isInvalid: false },
        rating_visual: { value: '0', isInvalid: false },
        rating_preparation: { value: '0', isInvalid: false },
      })
    },
    ...refetchQueries,
  })

  function handleSubmit() {
    if (
      [
        formState.text,
        formState.rating_taste,
        formState.rating_visual,
        formState.rating_preparation,
      ].some((x) => x.isInvalid === true || x.value === '0')
    ) {
      return setError(true)
    }
    const data: CreateCommentInput = {
      text: formState.text.value,
      ratingTaste: parseInt(formState.rating_taste.value),
      ratingVisual: parseInt(formState.rating_visual.value),
      ratingPreparation: parseInt(formState.rating_preparation.value),
      foodId,
    }
    setError(false)
    return reg({ variables: { data } })
  }

  useEffect(() => {
    if (
      formState.text.isInvalid ||
      formState.rating_taste.isInvalid ||
      formState.rating_visual.isInvalid ||
      formState.rating_preparation.isInvalid
    ) {
      setSubmitButtonInactive(true)
    } else {
      setError(false)

      setSubmitButtonInactive(false)
    }
  }, [formState])

  function handleValidation(value: string, type: string) {
    if (type === 'text') {
      return false
    }
    if (type === 'rating') {
      return !/^[1-5]?$/.test(value)
    }
    return false
  }

  function handleEnter(event: any) {
    if (event.keyCode === 13 && ref && ref.current) {
      event.preventDefault()
      ref.current.click()
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', handleEnter)

    return () => {
      window.removeEventListener('keyup', handleEnter)
    }
  }, [])

  if (!user || user.adminLevel === 0) {
    return (
      <Container>
        <h2>Přidat hodnocení</h2>
        <Alert color="error" sx={{ display: 'inline-flex' }}>
          <span>
            Pro přidání komentářů je nutné <NavLink to={routes.login}>přihlášení</NavLink>
          </span>
        </Alert>
      </Container>
    )
  }

  if (!userData.data?.user.isEmailVerified) {
    return (
      <Container>
        <h2>Přidat hodnocení</h2>
        <Alert color="error" sx={{ display: 'inline-flex' }}>
          <span>Pro přidání komentářů musíte mít ověřený email.</span>
        </Alert>
      </Container>
    )
  }

  return (
    <Container>
      <h2>Přidat hodnocení</h2>
      <p>Vyber pomocí hvězdiček, jak ti chutnalo:</p>
      <ComentForm>
        <p>
          <RatingLabel>Chuť:</RatingLabel>
          <Rating
            allowHover={false}
            size={28}
            onClick={(rate) =>
              setFormState({
                ...formState,
                rating_taste: {
                  value: rate.toString(),
                  isInvalid: handleValidation(rate.toString(), 'rating'),
                },
              })
            }
            initialValue={parseInt(formState.rating_taste.value)}
          />
        </p>
        <p>
          <RatingLabel>Vzhled:</RatingLabel>
          <Rating
            allowHover={false}
            size={28}
            onClick={(rate) =>
              setFormState({
                ...formState,
                rating_visual: {
                  value: rate.toString(),
                  isInvalid: handleValidation(rate.toString(), 'rating'),
                },
              })
            }
            initialValue={parseInt(formState.rating_visual.value)}
          />
        </p>
        <p>
          <RatingLabel>Příprava:</RatingLabel>
          <Rating
            allowHover={false}
            size={28}
            onClick={(rate) =>
              setFormState({
                ...formState,
                rating_preparation: {
                  value: rate.toString(),
                  isInvalid: handleValidation(rate.toString(), 'rating'),
                },
              })
            }
            initialValue={parseInt(formState.rating_preparation.value)}
          />
        </p>

        <TextAreaWrap>
          <p>Neveřejné hodnocení (nepovinné)</p>
          <TextArea
            placeholder='Zde můžeš zanechat dojmy z jídla a podněty pro kuchařky. Kritika se bere, ale slušná a konstruktivní - nepiš "byl to hnus", piš "dneska mi to nechutnalo", ideálně napiš proč - neokořeněné, přesolené...
              Paní kuchařky fotí všechny fotky, projektu fandí, a chtějí vařit tak, aby to co nejvíc lidem chutnalo - takže když ti to nechutná, tak napiš proč, ať vědí, co udělat příště líp. Díky'
            value={formState.text.value}
            isInvalid={formState.text.isInvalid}
            onChange={(e) =>
              setFormState({
                ...formState,
                text: {
                  value: e.target.value,
                  isInvalid: handleValidation(e.target.value, 'text'),
                },
              })
            }
          />
        </TextAreaWrap>

        {err && <LoginError>Vyplňte správně všechna pole</LoginError>}
        <Button ref={ref} disabled={submitButtonInactive} onClick={handleSubmit}>
          Přidat hodnocení
        </Button>
      </ComentForm>
    </Container>
  )
}

export default AddComment

const Container = styled.div``

const ComentForm = styled.div``

const LoginError = styled.div`
  color: #fc6969;
  font-weight: 500;
  font-size: 0.81em;
`

const NavLink = styled(Link)`
  color: black;
`

const TextAreaWrap = styled.div`
  width: 100%;
  max-width: 700px;
  box-sizing: border-box;
  margin: 40px auto 20px auto;
  text-align: left;

  p {
    font-weight: 500;
    font-size: 0.81em;
    margin-bottom: 4px;
  }
`

const RatingLabel = styled.span`
  display: inline-block;
  width: 80px;
  text-align: right;
  margin-right: 10px;
`
