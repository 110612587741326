import React from 'react'
import { Navigate } from 'react-router-dom'
import Props from './Props'
import useLogin from '../hooks/useLogin'
import { routes } from 'src/constants/routes'

interface CustomAuthorizedRouteProps extends Props {
  adminLevel: number
}

const CustomAuthorizedRoute = ({ adminLevel, children }: CustomAuthorizedRouteProps) => {
  const { user } = useLogin()

  return user && user.adminLevel >= adminLevel ? children : <Navigate to={routes.login} />
}

export default CustomAuthorizedRoute
