import React, { useState } from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import Select from 'react-select'
import { Close as CloseIcon } from '@mui/icons-material'

import HomeBigItem from '../../layout/HomeBigItem'
import { backgroundGray } from '../../layout/colors'
import canteens from '../../queries/v2/canteens'
import LoaderSpinner from '../../layout/loader'
import { customStyles } from '../editUser/Main'
import useLogin from 'src/hooks/useLogin'

interface Props {
  searchValue: string
}

const Canteens = ({ searchValue }: Props) => {
  const { user } = useLogin()
  const [filter, setFilter] = useState('')
  let selectRef: any = null

  const { loading, error, data } = useQuery(canteens)

  if (loading ?? !data) {
    return <LoaderSpinner />
  }

  if (error) {
    return <p>Error :(</p>
  }

  const filtered = data.canteens.filter((item: any) =>
    searchValue.length > 0
      ? (item.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
          item.region.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0) &&
        item.region.toLowerCase().indexOf(filter.toLowerCase()) >= 0
      : item.region.toLowerCase().indexOf(filter.toLowerCase()) >= 0,
  )

  const options = data.canteens
    .map(({ region }) => ({ value: region, label: `${region}` }))
    .filter((option, index, self) => index === self.findIndex((t) => t.value === option.value))

  return (
    <>
      <Container>
        <FilterContainer>
          <FilterText>Filtr:</FilterText>
          <FilterSelect
            ref={(ref) => {
              selectRef = ref
            }}
            isSearchable={true}
            styles={customStyles}
            options={options}
            value={options.find((obj) => obj.value === filter)}
            // @ts-ignore
            onChange={(e) => setFilter(e ? e.value : '')}
            placeholder={'Filtrovat kraj'}
            noOptionsMessage={() => 'Žádné kraje neodpovídají zadanému filtru'}
          />
          <UnsetFilter onClick={() => selectRef.clearValue()} />
        </FilterContainer>

        <ItemsContainer>
          {filtered.map((item) => {
            return (
              <HomeBigItem item={item} key={item.id} isUserPrefence={item.id === user?.canteenId} />
            )
          })}
        </ItemsContainer>
      </Container>
    </>
  )
}

export default Canteens

const Container = styled.div`
  padding: 10px 10%;
  background-color: ${backgroundGray};
`

const ItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`

const FilterText = styled.p`
  margin-right: 20px;
`

const FilterSelect = styled(Select)`
  width: 250px;
`

const UnsetFilter = styled(CloseIcon)`
  position: relative;
  bottom: 0px;
  left: -75px;
  color: hsl(0, 0%, 80%);
  transition: 2s;

  &:hover {
    color: #777777;
  }
`
