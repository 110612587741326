import { gql } from 'src/__generated__'
import { FoodsQueryQuery } from 'src/__generated__/graphql'

export type Food = FoodsQueryQuery['foods']

export default gql(`
  query foodsQuery($canteenId: Int!, $offset: Int, $limit: Int, $search: String) {
    foods(query: {canteenId: $canteenId, offset: $offset, limit: $limit, search: $search}) {
      id
      name
      photos {
        id
        s3url
      }
    }
  }
`)
