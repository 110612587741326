import React, { useState } from 'react'
import styled from 'styled-components'
import Search from '../../layout/Search'
import Canteens from './Canteens'
import { media } from 'src/styles'
import { navy } from 'src/layout/colors'

const Main = () => {
  const [searchValue, setSearchValue] = useState('')

  return (
    <>
      <HeaderImage searchValue={searchValue}>
        <Center>
          <SearchHeading>Seznam jídelen</SearchHeading>
          <Search
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            clearSearch={() => setSearchValue('')}
            placeholder="Vyhledat jídelnu"
          />
        </Center>
      </HeaderImage>
      <Canteens searchValue={searchValue} />
    </>
  )
}

export default Main

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
`

const SearchHeading = styled.h1`
  margin-top: 0;
  margin-bottom: 20px;
  color: #fff;

  ${media.sm`
    margin-bottom: 48px;
  `}
`

interface HeaderImageProps {
  searchValue: string
}

const HeaderImage = styled.div<HeaderImageProps>`
  background: url('/img/pattern_modry.svg'), ${navy};
  background-attachment: fixed;
  background-size: 730px;
  background-position: center;
  overflow: visible;
  height: ${(props) => (props.searchValue ? '10vh' : '50vh')};
  transition: height 0.5s;

  ${media.sm`
    min-height: 200px;
  `}

  ${media.maxSm`
    height: auto;
    padding: 20px 12px;
  `}
`
