import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useMutation } from '@apollo/client'

import { navy, backgroundGray } from '../../layout/colors'
import TextInput, { TextArea } from '../../layout/textInput'
import { InputValueStateType } from '../../constants/types'
import { useNavigate, useParams } from 'react-router'
import addFood from '../../mutations/v2/addFood'
import foods from '../../queries/v2/foods'
import { routes } from 'src/constants/routes'
import { CreateFoodInput } from 'src/__generated__/graphql'
import Button from 'src/components/Button'

const AddFood = () => {
  const navigate = useNavigate()
  const ref = useRef<HTMLButtonElement>(null)
  const [submitButtonInactive, setSubmitButtonInactive] = useState(true)
  const [error, setError] = useState(false)
  const { id } = useParams<{ id: string }>()

  const [formState, setFormState] = useState<InputValueStateType<'name' | 'description'>>({
    name: { value: '', isInvalid: false },
    description: { value: '', isInvalid: false },
  })

  const refetchQueries = {
    refetchQueries: [
      {
        query: foods,
        variables: { canteenId: parseInt(id!) },
      },
    ],
  }
  const [reg] = useMutation(addFood, {
    ...refetchQueries,
    onCompleted(food) {
      navigate(routes.foodDetail(food.createFood.id))
    },
  })

  function handleSubmit() {
    if (formState.name.isInvalid) {
      return setError(true)
    }
    const data: CreateFoodInput = {
      name: formState.name.value,
      description: formState.description?.value,
      canteenId: parseInt(id!),
    }
    setError(false)
    return reg({ variables: { data } })
  }

  useEffect(() => {
    if (formState.name.isInvalid) setSubmitButtonInactive(true)
    else setSubmitButtonInactive(false)
  }, [formState])

  function handleValidation(value: string) {
    return !/^[\sa-zá-žA-ZÁ-Ž0-9,._-]+$/.test(value)
  }

  function handleEnter(event: any) {
    if (event.keyCode === 13 && ref && ref.current) {
      event.preventDefault()
      ref.current.click()
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', handleEnter)

    return () => {
      window.removeEventListener('keyup', handleEnter)
    }
  }, [])

  return (
    <Container100vh>
      <Container>
        <Form>
          <Heading>Přidání nového jídla</Heading>
          <TextInput
            type="text"
            placeholder="Název jídla"
            value={formState.name.value}
            isInvalid={formState.name.isInvalid}
            onChange={(e) =>
              setFormState({
                ...formState,
                name: {
                  value: e.target.value,
                  isInvalid: handleValidation(e.target.value),
                },
              })
            }
          />
          <TextArea
            placeholder="Popis jídla"
            value={formState.description.value}
            isInvalid={formState.description.isInvalid}
            onChange={(e) =>
              setFormState({
                ...formState,
                description: {
                  value: e.target.value,
                  isInvalid: handleValidation(e.target.value),
                },
              })
            }
          />
          <div>{error && <LoginError>Chyba</LoginError>}</div>{' '}
          <Button ref={ref} disabled={submitButtonInactive} onClick={handleSubmit}>
            Přidat jídlo
          </Button>
        </Form>
      </Container>
    </Container100vh>
  )
}

export default AddFood

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;

  background: url('/img/pattern_modry.svg'), ${navy};
  background-attachment: fixed;
  background-position: center;
  background-size: 730px;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  padding: 30px 0;
  height: 100%;
`

const Form = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 50px 20px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  background-color: white;

  @media (min-width: 500px) {
    padding: 20px 50px 50px 50px;
    width: 70%;
  }

  @media (min-width: 600px) {
    width: 50%;
  }

  @media (min-width: 900px) {
    width: 30%;
  }

  @media (min-width: 2000px) {
    width: 20%;
  }

  @media (min-width: 3000px) {
    width: 15%;
  }
`

const Heading = styled.h2`
  text-align: center;
`

const LoginError = styled.span`
  color: #fc6969;
  font-weight: 500;
  font-size: 0.81em;
  position: absolute;
  margin-bottom: 0.75em;
  margin-top: -0.8em;
  display: flex;
  justify-content: flex-start;
`
