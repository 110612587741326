import { gql } from 'src/__generated__'

export default gql(`
  query generateMonthOffersQuery($canteenId: Int!, $date: DateTime!) {
    generateMonthOffers(canteenId: $canteenId, date: $date) {
      id
      date
      canteenId
      food {
        canteenId
        id
        name
        tags {
          name
        }
      }
    }
  }
`)
