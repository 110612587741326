import React, { useEffect, useState } from 'react'
import { useParams, Navigate } from 'react-router'
import { useMutation, useQuery } from '@apollo/client'
import {
  AddCircle as AddCircleIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material'
import { Box } from '@mui/material'

import styled from 'styled-components'
import { Link } from 'react-router-dom'
import FsLightbox from 'fslightbox-react'
import { useConfirm } from 'material-ui-confirm'

import LoaderSpinner from '../../layout/loader'
import food from '../../queries/v2/food'
import useLogin from '../../hooks/useLogin'
import Comments from './Comments'
import removePhoto from '../../mutations/v2/removePhoto'
import { navy } from 'src/layout/colors'
import { routes } from 'src/constants/routes'
import AddComment from './AddComment'
import { UserAdminLevel } from 'src/constants/constants'
import { getStars } from './Comment'
import Button, { ButtonLink } from 'src/components/Button'

const Food = () => {
  const confirm = useConfirm()
  const { user } = useLogin()
  const { id } = useParams<{ id: string }>()
  const [isOpen, setIsOpen] = useState(0)

  const { loading, error, data, refetch } = useQuery(food, {
    variables: { id: parseInt(id!) },
  })

  const refetchQueries = {
    refetchQueries: [
      {
        query: food,
        variables: {
          id: parseInt(id!),
        },
      },
    ],
  }
  const [req] = useMutation(removePhoto, {
    ...refetchQueries,
  })

  useEffect(() => {
    const timeout = setTimeout(() => {
      refetch({ id: parseInt(id!) })
    }, 1000)

    return () => clearTimeout(timeout)
  }, [])

  if (error) {
    return <Navigate to={routes.main} />
  }

  if (loading) {
    return <LoaderSpinner />
  }

  function handleDelete(imgId: number) {
    confirm({
      title: `Opravdu chcete fotku smazat?`,
      description: `Tato akce je nevratná.`,
      cancellationText: 'Zrušit',
    }).then(() => {
      req({ variables: { id: imgId } })
    })
  }

  const hasPhotos = !!data?.food.photos?.length
  const userIsCanteenAdmin =
    user &&
    (user.adminLevel >= 3 || (user.adminLevel === 2 && user.canteenId === data?.food.canteenId))

  return (
    <>
      <Container100vh>
        <Container>
          <Inner>
            <Box display="flex" alignItems="center" justifyContent="center" style={{ gap: '24px' }}>
              <Headline>
                {data?.food.name}
                {userIsCanteenAdmin && (
                  <Link to={routes.foodDetailEdit(data?.food.id!)} style={{ marginLeft: '24px' }}>
                    <Edit />
                  </Link>
                )}
              </Headline>

              {data?.food.averageRating && (
                <StarWrap>
                  <strong>{data.food.averageRating?.toFixed(1)}</strong>
                  {getStars(1, 1, 32)}
                </StarWrap>
              )}
            </Box>

            {data?.food.description && <p>{data?.food.description}</p>}

            <ImagesContainer>
              {data?.food.photos?.map((img) => {
                return (
                  <ImageOuter key={img.id}>
                    <ImageContainer>
                      <a
                        href={img.s3url}
                        onClick={(e) => {
                          e.preventDefault()
                          setIsOpen((i) => ++i)
                        }}
                      >
                        <Image src={img.s3url} />
                      </a>
                      <FsLightbox
                        toggler={isOpen}
                        sources={[img.s3url]}
                        types={['image', 'image', 'image', 'image', 'image', 'image']}
                      />
                    </ImageContainer>
                    {userIsCanteenAdmin && (
                      <Button onClick={() => handleDelete(img.id)}>
                        <DeleteIcon />
                        <span>Smazat fotku</span>
                      </Button>
                    )}
                  </ImageOuter>
                )
                //return <Image url={img.s3url} />
              })}
            </ImagesContainer>
            {userIsCanteenAdmin && (
              <ButtonLink to={routes.foodDetailAddPhoto(data?.food.id!)} disabled={hasPhotos}>
                <AddCircleIcon />
                <span>Přidat fotku</span>
              </ButtonLink>
            )}
            {!user && (
              <p>
                Pro přidání komentáře se <NavLink to={routes.login}>přihlas</NavLink>
              </p>
            )}
            {user?.adminLevel === UserAdminLevel.USER &&
              user.canteenId === data?.food.canteenId && <AddComment foodId={parseInt(id!)} />}
            {user && (
              <Comments
                foodId={parseInt(id!)}
                userCanEditComment={user.adminLevel === UserAdminLevel.SUPERADMIN}
              />
            )}
            {/* <p>{data.canteen.currentCanteen.region} region</p> */}
          </Inner>
        </Container>
      </Container100vh>
    </>
  )
}

export default Food

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  background: url('/img/pattern_modry.svg'), ${navy};
  background-attachment: fixed;
  background-position: center;
  background-size: 730px;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  height: 100%;
`

const Inner = styled.div`
  text-align: center;
  background-color: white;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  width: 80%;
  margin: 10px auto;
  border-radius: 5px;
  padding: 20px 20px;
  box-sizing: border-box;
`

const Headline = styled.h1`
  margin-left: 32px;
  margin-right: 32px;
`

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  flex-direction: column;

  @media (min-width: 530px) {
    flex-direction: row;
  }
`

interface ImageProps {
  src: string
}

const ImageContainer = styled.div`
  background-position: center;
  background-size: cover;
  height: 300px;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  max-width: 420px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  margin: 10px 0;
`

const Image = styled.img`
  height: 300px;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  max-width: 420px;
`

const ImageOuter = styled.div`
  max-width: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 530px) {
    flex: 1 0 420px !important;
    margin: 10px auto;
    flex-basis: min-content;
  }
`

const NavLink = styled(Link)`
  color: black;
`

const Edit = styled(EditIcon)`
  color: ${navy};
`

const StarWrap = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 28px;
`
