import React from 'react'
import styled from 'styled-components'
import { routes } from 'src/constants/routes'
import { ButtonLink } from 'src/components/Button'
import { media } from 'src/styles'

const Buttons = () => {
  return (
    <ButtonsWrap>
      <ButtonLink variant="outlined" to={routes.login}>
        Přihlášení
      </ButtonLink>
      <ButtonLink to={routes.register}>Registrace</ButtonLink>
    </ButtonsWrap>
  )
}
export default Buttons

const ButtonsWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;

  ${media.lg`
    margin-top: 0px;
    margin-left: 20px;
    flex-direction: row;
  `}
`
