import { navy } from 'src/layout/colors'
import { media } from 'src/styles'
import styled from 'styled-components'

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  padding-bottom: 32px;

  a {
    text-decoration: none;
    color: inherit;
    flex: 1;
    max-width: 300px;
  }

  ${media.maxSm`
    flex-wrap: wrap;
    gap: 7px;
    border-bottom: 2px solid ${navy};
    margin-bottom: 48px;
    padding-bottom: 48px;
  `}
`

export const FoodItemContainer = styled.div`
  flex: 1;
  max-width: 300px;
  text-align: center;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  font-weight: bold;
  transition: 0.5s;
  padding: 15px;

  &:hover {
    box-shadow: 0rem 0.5rem 1.5rem rgb(0 0 0 / 30%);
    //transform: scale(1.01);
  }

  span {
    font-size: 12px;
  }

  p {
    margin-bottom: 0;
  }
`

export const Image = styled.img`
  object-fit: cover;
  height: 200px;
  flex-basis: min-content;
  width: 100%;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;

  ${media.maxSm`
    height: 110px;
  `}
`

export const ImageContainer = styled.div`
  position: relative;
`

export const Buttons = styled.div`
  margin: 0 32px;

  ${media.maxSm`
    order: 3;
    width: 100%;

    h3 {
      margin: 8px 0 12px;
    }
  `}
`

export const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${media.maxSm`
    flex-direction: row;
    width: 100%;

    button {
      min-width: auto;
      flex: 1;
    }
  `}
`
