import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { useConfirm } from 'material-ui-confirm'
import { useMutation } from '@apollo/client'

import { backgroundGray, navy } from '../../layout/colors'
import removeFoodFromOffer from 'src/mutations/v2/removeFoodFromOffer'
import { media } from 'src/styles'
import { routes } from 'src/constants/routes'
import { CanteenOffer } from 'src/queries/v2/canteenOfferHistory'
import { getStars } from '../food/Comment'

export interface FoodType {
  name?: string
  id: number
}

interface Props {
  food: CanteenOffer['food']
  offerId: number
  canDeleteFood?: boolean
  color?: string
  isLastDay?: boolean
  isLast?: boolean
  refetchQueries?: () => void
}

const Food = ({
  food,
  offerId,
  canDeleteFood,
  color,
  isLastDay,
  isLast,
  refetchQueries,
}: Props) => {
  const confirm = useConfirm()
  const navigate = useNavigate()

  const [req] = useMutation(removeFoodFromOffer, {
    onCompleted: () => {
      refetchQueries?.()
    },
  })

  function handleDelete(foodName: string, foodId: number) {
    confirm({
      title: `Smazat ${foodName}?`,
      description: `Tato akce je nevratná.`,
      cancellationText: 'Zrušit',
    }).then(() => {
      req({ variables: { data: { foodId, offerId } } })
    })
  }

  return (
    <>
      {food.length !== 0 ? (
        <>
          {food.map((pokrm, i: number) => {
            return (
              <Row>
                <Td onClick={() => navigate(routes.foodDetail(pokrm.id))}>Oběd {i + 1}</Td>
                <Td onClick={() => navigate(routes.foodDetail(pokrm.id))}>
                  <NameWithStartWrap>
                    {pokrm.name}
                    {pokrm.averageRating && (
                      <StarWrap>
                        <strong>{pokrm.averageRating?.toFixed(1)}</strong>
                        {getStars(1, 1)}
                      </StarWrap>
                    )}
                  </NameWithStartWrap>
                </Td>
                {canDeleteFood && (
                  <Td align="right">
                    {/* <Delete
                      onClick={() => {
                        handleDelete(pokrm.name ?? '', pokrm.id)
                      }}
                      bgColor={color}
                    /> */}
                  </Td>
                )}
              </Row>
            )
          })}
          {isLastDay && !isLast ? (
            <LineRow>
              <LineBox colSpan={3}>
                <InnerLine></InnerLine>
              </LineBox>
            </LineRow>
          ) : null}
        </>
      ) : (
        <Row>
          <Td>Nic v nabídce</Td>
          <Td></Td>
          <Td></Td>
        </Row>
      )}
    </>
  )
}

export default Food

const Row = styled.tr`
  &:nth-child(odd) {
    background-color: ${backgroundGray};
  }

  &:hover {
    background-color: #e9e9e9;
  }
`

const Td = styled.td`
  padding: 5px 10px;
  cursor: pointer;

  &:first-child {
    white-space: nowrap;
    ${media.sm`
      width: 100px;
    `}
  }

  &:last-child {
    display: flex;
    justify-content: ${({ align }) => (align === 'right' ? 'flex-end' : '')};
  }
`

interface DeleteIconProps {
  bgColor?: string
}

const Delete = styled(DeleteIcon)<DeleteIconProps>`
  color: ${(props) => props.bgColor || navy};
`

const LineRow = styled.tr``

const LineBox = styled.td`
  padding: 10px 0;
`

const InnerLine = styled.div`
  background-color: #c9c9c9;
  height: 3px;
`

const NameWithStartWrap = styled.span`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  gap: 4px;

  ${media.sm`
    justify-content: flex-start;
    gap: 8px;
  `}

  ${media.md`
    gap: 48px;
  `}
`

const StarWrap = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`
