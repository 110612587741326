import { gql } from 'src/__generated__'
import { CanteenQueryQuery } from 'src/__generated__/graphql'

export type Canteen = CanteenQueryQuery['canteen']

export default gql(`
  query canteenQuery($id: Int!) {
    canteen(id: $id) {
      id
      name
      region
      city
      slug
      canteenSystemType
      externalSystemId
      lastOfferUpdateAt
    }
  }
`)
