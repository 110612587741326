import React from 'react'
import { Navigate, useParams } from 'react-router-dom'

import useLogin from '../../hooks/useLogin'
import { routes } from 'src/constants/routes'

import Main from './Main'

const FoodAdminDuplicates = () => {
  const loginData = useLogin()
  const params = useParams<{ id?: string }>()

  if (!loginData.user || !params.id) {
    return <Navigate to={routes.login} />
  }

  const canteenId = parseInt(params.id)
  return <Main canteenId={canteenId} />
}

export default FoodAdminDuplicates
