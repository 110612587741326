import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import { ExitToApp as ExitToAppIcon, AccountCircle as AccountCircleIcon } from '@mui/icons-material'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import YoutubeIcon from '@mui/icons-material/YouTube'
import { Link, useLocation } from 'react-router-dom'

import useLogin from '../../../hooks/useLogin'
import Buttons from '../Buttons'
import logout from '../../../mutations/v2/logout'
import handleLogout from '../../handleLogout'
import { UserAdminLevel } from 'src/constants/constants'
import { routes } from 'src/constants/routes'
import { navy } from 'src/layout/colors'

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
}

export const MAIN_MENU_ITEMS = [
  { name: 'Domů', link: window?.location.origin ?? routes.index },
  { name: 'Seznam jídelen', link: routes.main },
  { name: 'O nás', link: routes.about },
  { name: 'Příběh jídla', link: routes.foodWaste },
  { name: 'Jak to funguje', link: routes.howItWorks },
]
export const ADMIN_MENU_ITEMS = [
  { name: 'Uživatelé', link: routes.users },
  { name: 'Administrace jídelen', link: routes.canteens },
]
export const CANTEEN_MENU_ITEMS = (canteenId: number) => [
  { name: 'Jídelníček', link: routes.canteenDetail(canteenId) },
  { name: 'Administrace pokrmů', link: routes.canteenDetailFood(canteenId) },
  { name: 'Hodnocení', link: routes.canteenDetailComments(canteenId) },
  // { name: 'Schválení fotografií', link: routes.photosAdmin(canteenId) },
]
export const SOCIALS_MENU_ITEMS = [
  { name: 'Instagram', link: 'https://www.instagram.com/natacu.cz/', icon: <InstagramIcon /> },
  { name: 'Youtube', link: 'https://www.youtube.com/@Natacu', icon: <YoutubeIcon /> },
  { name: 'Facebook', link: 'https://www.facebook.com/natacu.cz', icon: <FacebookIcon /> },
]

const HamburgerMenu = ({ setOpen, open }: Props) => {
  const { user } = useLogin()
  const [logoutUser] = useMutation(logout)
  const { pathname } = useLocation()

  // useEffect(() => {
  //   window.addEventListener('scroll', () => setOpen(false))
  //   return () => window.removeEventListener('scroll', () => setOpen(false))
  // }, [])

  useEffect(() => {
    setOpen(false)
  }, [pathname])

  return (
    <Container open={open}>
      <Links>
        {MAIN_MENU_ITEMS.map((item) => (
          <NavLink
            onClick={() => setOpen(false)}
            to={item.link}
            active={pathname === item.link}
            // reload document on home page, because it has its own html file
            reloadDocument={item.link.includes('http')}
          >
            {item.name}
          </NavLink>
        ))}

        {user && user.adminLevel === UserAdminLevel.SUPERADMIN && (
          <>
            <MenuSeparator>Admin:</MenuSeparator>
            {ADMIN_MENU_ITEMS.map((item) => (
              <NavLink
                onClick={() => setOpen(false)}
                to={item.link}
                active={pathname === item.link}
              >
                {item.name}
              </NavLink>
            ))}
          </>
        )}

        {user && user.adminLevel >= 2 && user.canteenId && (
          <>
            <MenuSeparator>Moje jídelna:</MenuSeparator>
            {CANTEEN_MENU_ITEMS(user.canteenId).map((item) => (
              <NavLink
                onClick={() => setOpen(false)}
                to={item.link}
                active={pathname === item.link}
              >
                {item.name}
              </NavLink>
            ))}
          </>
        )}

        {user && (
          <>
            <MenuSeparator>Můj profil:</MenuSeparator>
            <NavLink
              onClick={() => setOpen(false)}
              to={routes.editUser}
              active={pathname === routes.editUser}
            >
              <AccountCircleIcon />
              {user.userName}
            </NavLink>
            <Href onClick={() => handleLogout(logoutUser)}>
              <ExitToAppIcon />
              Odhlásit&nbsp;se
            </Href>
          </>
        )}

        <MenuSeparator>Sledujte nás:</MenuSeparator>
        {SOCIALS_MENU_ITEMS.map((item) => (
          <NavLink target="_blank" to={item.link}>
            {item.icon} {item.name}
          </NavLink>
        ))}

        {!user && <Buttons />}
        {/* <MenuMakerLink mobile={true}>
          <NavLink to="/menuMakerForm/1">Menu maker</NavLink>
        </MenuMakerLink> */}
      </Links>
    </Container>
  )
}
export default HamburgerMenu

interface containerProps {
  open: boolean
}

export const ExternalLink = styled.a`
  text-decoration: none;
  padding: 10px 15px;
  color: white;
`

const Container = styled.div<containerProps>`
  min-height: 100vh;
  text-align: left;
  position: fixed;
  padding-top: 100.88px;
  top: 0;
  bottom: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  background-color: white;
  display: flex;
  overflow: hidden;
  z-index: 999;

  width: 70vw;

  @media (min-width: 400px) {
    width: 50vw;
  }

  @media (min-width: 600px) {
    width: 30vw;
  }

  transform: translateX(${({ open }) => (open ? '0%' : '100%')});
`

const Links = styled.div`
  flex: 2;
  padding: 10px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 80vh;
  padding-right: 20px;
  margin-right: -20px;
`

const NavLink = styled(Link)<{ active: boolean }>`
  padding: 10px;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  color: ${({ active }) => (active ? navy : 'black')};
  font-weight: ${({ active }) => (active ? 'bold' : 'regular')};

  &:hover {
    font-weight: bold;
  }

  svg {
    margin-right: 5px;
  }
`
const MenuSeparator = styled.div`
  font-weight: bold;
  padding: 10px;
  margin-top: 24px;
`

const Href = styled.a`
  padding: 10px;
  text-decoration: none;
  color: black;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  &:hover {
    font-weight: bold;
  }
`
