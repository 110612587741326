import styled from 'styled-components'

export const Wrap = styled.div`
  padding: 12px;
  background: #da3636;
  color: #fff;

  p {
    text-align: center;
  }

  a,
  span {
    color: inherit;
    display: inline-block;
    margin-left: 12px;

    &:hover {
      text-decoration: none;
    }
  }
`
