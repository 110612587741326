import React from 'react'
import styled from 'styled-components'
import { ExitToApp as ExitToAppIcon, AccountCircle as AccountCircleIcon } from '@mui/icons-material'
import { useMutation } from '@apollo/client'
import { Link, useLocation } from 'react-router-dom'

import Buttons from '../Buttons'
import useLogin from '../../../hooks/useLogin'
import logout from '../../../mutations/v2/logout'
import handleLogout from '../../handleLogout'
import { UserAdminLevel } from 'src/constants/constants'
import { routes } from 'src/constants/routes'
import { navy } from 'src/layout/colors'
import {
  ADMIN_MENU_ITEMS,
  CANTEEN_MENU_ITEMS,
  MAIN_MENU_ITEMS,
  SOCIALS_MENU_ITEMS,
} from '../MobileMenu/HamburgerMenu'

const DesktopMenu = () => {
  const { user } = useLogin()
  const [logoutUser] = useMutation(logout)
  const { pathname } = useLocation()

  return (
    <Nav>
      {MAIN_MENU_ITEMS.map((item) => (
        <NavLink
          to={item.link}
          active={pathname === item.link}
          // reload document on home page, because it has its own html file
          reloadDocument={item.link.includes('http')}
        >
          {item.name}
        </NavLink>
      ))}

      {user && user.adminLevel === UserAdminLevel.SUPERADMIN && (
        <>
          {ADMIN_MENU_ITEMS.map((item) => (
            <NavLink to={item.link} active={pathname === item.link}>
              {item.name}
            </NavLink>
          ))}
        </>
      )}

      {user && user.adminLevel >= 2 && user.canteenId && (
        <>
          {CANTEEN_MENU_ITEMS(user.canteenId).map((item) => (
            <NavLink to={item.link} active={pathname === item.link}>
              {item.name}
            </NavLink>
          ))}
        </>
      )}

      {user && (
        <NavLink to={routes.editUser}>
          <AccountIcon />
          {user.userName}
        </NavLink>
      )}
      {user && (
        <Href onClick={() => handleLogout(logoutUser)}>
          <LogoutIcon />
          Odhlásit se
        </Href>
      )}

      {!user && <Buttons />}

      <Separator />

      {SOCIALS_MENU_ITEMS.map((item) => (
        <NavLink target="_blank" to={item.link}>
          {item.icon}
        </NavLink>
      ))}
    </Nav>
  )
}
export default DesktopMenu

const Nav = styled.nav`
  display: flex;
  align-items: center;
`

const NavLink = styled(Link)<{ active: boolean }>`
  padding: 10px;
  text-decoration: none;
  color: black;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  color: ${({ active }) => (active ? navy : 'black')};
  font-weight: ${({ active }) => (active ? 'bold' : 'regular')};

  svg {
    color: ${navy};

    &:hover {
      color: #00384b;
    }
  }
`

const Separator = styled.div`
  width: 10px;
`

const AccountIcon = styled(AccountCircleIcon)`
  margin-right: 5px;
`

const LogoutIcon = styled(ExitToAppIcon)`
  margin-right: 5px;
`

const Href = styled.a`
  padding: 10px;
  text-decoration: none;
  color: black;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  &:hover {
    font-weight: bold;
  }
`
