import React from 'react'
import styled from 'styled-components'
import { DoubleArrow as DoubleArrowIcon } from '@mui/icons-material'

import { Canteen } from 'src/queries/v2/canteens'
import { routes } from 'src/constants/routes'
import { media } from 'src/styles'
import { ButtonLink } from 'src/components/Button'

interface Props {
  item: Canteen
  isUserPrefence?: boolean
}

const HomeBigItem = ({ item, isUserPrefence }: Props) => {
  return (
    <Container highlight={!!isUserPrefence}>
      <LeftSection>
        <Name>{item.name}</Name>
        <Region>
          {item.city ? `${item.city}, ` : ''}
          {item.region}
        </Region>
      </LeftSection>
      <ButtonLink to={routes.canteenDetail(item.id)}>
        <span>Zobrazit Jídelnu</span> <DoubleArrowIcon />
      </ButtonLink>
    </Container>
  )
}

export default HomeBigItem

const Container = styled.div<{ highlight: boolean }>`
  background-color: white;
  padding: 5px 10px 15px 10px;
  margin: 10px 0px;
  text-align: left;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 0;
  min-width: 200px;

  ${({ highlight }) =>
    highlight &&
    `
    border: 3px solid #00547152;
  `}

  ${media.sm`
    margin: 30px 10px;
    padding: 5px 10px;
  `}

  @media (min-width: 600px) {
    margin: 30px;
    max-width: 200px;
  }
`

const LeftSection = styled.div`
  text-align: center;
`

const Name = styled.h3`
  font-size: 23px;
  margin-bottom: 0;
  text-transform: capitalize;
`

const Region = styled.p`
  margin-top: 0;
  color: #888888;
`
