/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation createCanteen($data: CreateCanteenInput!) {\n    createCanteen(data: $data) {\n      id\n    }\n  }\n": types.CreateCanteenDocument,
    "\n  mutation addFoodToOffer($data: AddFoodToOfferInput!) {\n    addFoodToOffer(data: $data) {\n      id\n    }\n  }\n": types.AddFoodToOfferDocument,
    "\n  mutation addFood($data: CreateFoodInput!) {\n    createFood(data: $data) {\n      id\n    }\n  }\n": types.AddFoodDocument,
    "\n  mutation approvePhoto($id: Int!) {\n    approvePhoto(id: $id) {\n      id\n    }\n  }\n": types.ApprovePhotoDocument,
    "\n  mutation createComment($data: CreateCommentInput!) {\n    createComment(data: $data) {\n      id\n    }\n  }\n": types.CreateCommentDocument,
    "\n  mutation updateCanteen($data: UpdateCanteenInput!) {\n    updateCanteen(data: $data) {\n      id\n    }\n  }\n": types.UpdateCanteenDocument,
    "\n  mutation updateFood($data: UpdateFoodInput!) {\n    updateFood(data: $data) {\n      id\n    }\n  }\n": types.UpdateFoodDocument,
    "\n  mutation updateUser($data: UpdateUserInput!) {\n    updateUser(data: $data) {\n      id\n    }\n  }\n": types.UpdateUserDocument,
    "\n  mutation forgotPasswordConfirm($data: ForgotPasswordConfirmInput!) {\n    forgotPasswordConfirm(data: $data)\n  }\n": types.ForgotPasswordConfirmDocument,
    "\n  mutation forgotPasswordRequest($data: ForgotPasswordRequestInput!) {\n    forgotPasswordRequest(data: $data)\n  }\n": types.ForgotPasswordRequestDocument,
    "\n  mutation login($email: String!, $password: String!) {\n    login(data: {email: $email, password: $password}) {\n      token\n      user {\n        id\n        email\n        userName\n        adminLevel\n        canteen {\n          id\n        }\n      }\n    }\n  }\n": types.LoginDocument,
    "\nmutation logout {\n  logout\n}\n": types.LogoutDocument,
    "\n  mutation refreshCanteen($canteenId: Int!) {\n    refreshCanteen(canteenId: $canteenId)\n  }\n": types.RefreshCanteenDocument,
    "\n  mutation register($data: RegisterInput!) {\n    register(data: $data) {\n      token\n      user {\n        id\n        email\n        userName\n        adminLevel\n        canteen {\n          id\n        }\n      }\n    }\n  }\n": types.RegisterDocument,
    "\n  mutation rejectFoodConflict($data: RejectFoodConflictInput!) {\n    rejectFoodConflict(data: $data)\n  }\n": types.RejectFoodConflictDocument,
    "\n  mutation removeCanteen($id: Int!) {\n    removeCanteen(id: $id)\n  }\n": types.RemoveCanteenDocument,
    "\n  mutation removeComment($id: Int!) {\n    removeComment(id: $id)\n  }\n": types.RemoveCommentDocument,
    "\n  mutation removeFood($id: Int!) {\n    removeFood(id: $id)\n  }\n": types.RemoveFoodDocument,
    "\n  mutation removeFoodFromOffer($data: RemoveFoodFromInput!) {\n    removeFoodFromOffer(data: $data) {\n      id\n    }\n  }\n": types.RemoveFoodFromOfferDocument,
    "\n  mutation removePhoto($id: Int!) {\n    removePhoto(id: $id)\n  }\n": types.RemovePhotoDocument,
    "\n  mutation removeUser($id: Int!) {\n    removeUser(id: $id)\n  }\n": types.RemoveUserDocument,
    "\n  mutation resolveFoodConflict($data: ResolveFoodConflictInput!) {\n    resolveFoodConflict(data: $data) {\n      id\n    }\n  }\n": types.ResolveFoodConflictDocument,
    "\n  mutation verifyAccountConfirm($data: VerifyAccountConfirmInput!) {\n    verifyAccountConfirm(data: $data)\n  }\n": types.VerifyAccountConfirmDocument,
    "\n  mutation verifyAccountRequest($data: VerifyAccountRequestInput!) {\n    verifyAccountRequest(data: $data)\n  }\n": types.VerifyAccountRequestDocument,
    "\n  query canteenQuery($id: Int!) {\n    canteen(id: $id) {\n      id\n      name\n      region\n      city\n      slug\n      canteenSystemType\n      externalSystemId\n      lastOfferUpdateAt\n    }\n  }\n": types.CanteenQueryDocument,
    "\n  query canteenOffersQuery($query: GetOffersInput!) {\n    canteenOffers(query: $query) {\n      id\n      date\n      food {\n        id\n        name\n        averageRating\n      }\n    }\n  }\n": types.CanteenOffersQueryDocument,
    "\n  query canteensQuery($slug: String, $offset: Int, $limit: Int) {\n    canteens(query: {slug: $slug, offset: $offset, limit: $limit}) {\n      id\n      name\n      region\n      city\n      canteenSystemType\n      externalSystemId\n    }\n  }\n": types.CanteensQueryDocument,
    "\n  query commentsQuery($query: GetCommentsArgs!) {\n    comments(query: $query) {\n      id\n      text\n      date\n      ratingTaste\n      ratingVisual\n      ratingPreparation\n      food {\n        id\n        name\n      }\n      user {\n        userName\n      }\n    }\n  }\n": types.CommentsQueryDocument,
    "\n  query foodQuery($id: Int!) {\n    food(id: $id) {\n      id\n      name\n      description\n      canteenId\n      averageRating\n      similarNames {\n        alternateName\n      }\n      photos {\n        id\n        s3url\n      }\n    }\n  }\n": types.FoodQueryDocument,
    "\n  query foodsQuery($canteenId: Int!, $offset: Int, $limit: Int, $search: String) {\n    foods(query: {canteenId: $canteenId, offset: $offset, limit: $limit, search: $search}) {\n      id\n      name\n      photos {\n        id\n        s3url\n      }\n    }\n  }\n": types.FoodsQueryDocument,
    "\n  query foodsConflictsQuery($canteenId: Int!) {\n    foodsConflicts(canteenId: $canteenId) {\n      id\n      name\n      description\n      photos {\n        id\n        s3url\n      }\n    }\n  }\n": types.FoodsConflictsQueryDocument,
    "\n  query generateNewFoodQuery($tags: [String!]!) {\n    generateNewFood(tags: $tags) {\n      ingredients\n      name\n      recipe\n    }\n  }\n": types.GenerateNewFoodQueryDocument,
    "\n  query generateMonthOffersQuery($canteenId: Int!, $date: DateTime!) {\n    generateMonthOffers(canteenId: $canteenId, date: $date) {\n      id\n      date\n      canteenId\n      food {\n        canteenId\n        id\n        name\n        tags {\n          name\n        }\n      }\n    }\n  }\n": types.GenerateMonthOffersQueryDocument,
    "\n  query photosQuery($foodId: Int,  $approved: Boolean) {\n    photos(query: {foodId: $foodId, approved: $approved}) {\n      id\n      s3url\n      food {\n        id\n        name\n      }\n    }\n  }\n": types.PhotosQueryDocument,
    "\n  query userQuery($userId: Int!) {\n    user(id: $userId) {\n      id\n      userName\n      email\n      isEmailVerified\n      adminLevel\n      canteen {\n        id\n      }\n    }\n  }\n": types.UserQueryDocument,
    "\n  query usersQuery {\n    users {\n      id\n      email\n      isEmailVerified\n      adminLevel\n      userName\n      canteen {\n        id\n        name\n      }\n    }\n  }\n": types.UsersQueryDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createCanteen($data: CreateCanteenInput!) {\n    createCanteen(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation createCanteen($data: CreateCanteenInput!) {\n    createCanteen(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation addFoodToOffer($data: AddFoodToOfferInput!) {\n    addFoodToOffer(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation addFoodToOffer($data: AddFoodToOfferInput!) {\n    addFoodToOffer(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation addFood($data: CreateFoodInput!) {\n    createFood(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation addFood($data: CreateFoodInput!) {\n    createFood(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation approvePhoto($id: Int!) {\n    approvePhoto(id: $id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation approvePhoto($id: Int!) {\n    approvePhoto(id: $id) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createComment($data: CreateCommentInput!) {\n    createComment(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation createComment($data: CreateCommentInput!) {\n    createComment(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateCanteen($data: UpdateCanteenInput!) {\n    updateCanteen(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation updateCanteen($data: UpdateCanteenInput!) {\n    updateCanteen(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateFood($data: UpdateFoodInput!) {\n    updateFood(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation updateFood($data: UpdateFoodInput!) {\n    updateFood(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateUser($data: UpdateUserInput!) {\n    updateUser(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation updateUser($data: UpdateUserInput!) {\n    updateUser(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation forgotPasswordConfirm($data: ForgotPasswordConfirmInput!) {\n    forgotPasswordConfirm(data: $data)\n  }\n"): (typeof documents)["\n  mutation forgotPasswordConfirm($data: ForgotPasswordConfirmInput!) {\n    forgotPasswordConfirm(data: $data)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation forgotPasswordRequest($data: ForgotPasswordRequestInput!) {\n    forgotPasswordRequest(data: $data)\n  }\n"): (typeof documents)["\n  mutation forgotPasswordRequest($data: ForgotPasswordRequestInput!) {\n    forgotPasswordRequest(data: $data)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation login($email: String!, $password: String!) {\n    login(data: {email: $email, password: $password}) {\n      token\n      user {\n        id\n        email\n        userName\n        adminLevel\n        canteen {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation login($email: String!, $password: String!) {\n    login(data: {email: $email, password: $password}) {\n      token\n      user {\n        id\n        email\n        userName\n        adminLevel\n        canteen {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation logout {\n  logout\n}\n"): (typeof documents)["\nmutation logout {\n  logout\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation refreshCanteen($canteenId: Int!) {\n    refreshCanteen(canteenId: $canteenId)\n  }\n"): (typeof documents)["\n  mutation refreshCanteen($canteenId: Int!) {\n    refreshCanteen(canteenId: $canteenId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation register($data: RegisterInput!) {\n    register(data: $data) {\n      token\n      user {\n        id\n        email\n        userName\n        adminLevel\n        canteen {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation register($data: RegisterInput!) {\n    register(data: $data) {\n      token\n      user {\n        id\n        email\n        userName\n        adminLevel\n        canteen {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation rejectFoodConflict($data: RejectFoodConflictInput!) {\n    rejectFoodConflict(data: $data)\n  }\n"): (typeof documents)["\n  mutation rejectFoodConflict($data: RejectFoodConflictInput!) {\n    rejectFoodConflict(data: $data)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation removeCanteen($id: Int!) {\n    removeCanteen(id: $id)\n  }\n"): (typeof documents)["\n  mutation removeCanteen($id: Int!) {\n    removeCanteen(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation removeComment($id: Int!) {\n    removeComment(id: $id)\n  }\n"): (typeof documents)["\n  mutation removeComment($id: Int!) {\n    removeComment(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation removeFood($id: Int!) {\n    removeFood(id: $id)\n  }\n"): (typeof documents)["\n  mutation removeFood($id: Int!) {\n    removeFood(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation removeFoodFromOffer($data: RemoveFoodFromInput!) {\n    removeFoodFromOffer(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation removeFoodFromOffer($data: RemoveFoodFromInput!) {\n    removeFoodFromOffer(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation removePhoto($id: Int!) {\n    removePhoto(id: $id)\n  }\n"): (typeof documents)["\n  mutation removePhoto($id: Int!) {\n    removePhoto(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation removeUser($id: Int!) {\n    removeUser(id: $id)\n  }\n"): (typeof documents)["\n  mutation removeUser($id: Int!) {\n    removeUser(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation resolveFoodConflict($data: ResolveFoodConflictInput!) {\n    resolveFoodConflict(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation resolveFoodConflict($data: ResolveFoodConflictInput!) {\n    resolveFoodConflict(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation verifyAccountConfirm($data: VerifyAccountConfirmInput!) {\n    verifyAccountConfirm(data: $data)\n  }\n"): (typeof documents)["\n  mutation verifyAccountConfirm($data: VerifyAccountConfirmInput!) {\n    verifyAccountConfirm(data: $data)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation verifyAccountRequest($data: VerifyAccountRequestInput!) {\n    verifyAccountRequest(data: $data)\n  }\n"): (typeof documents)["\n  mutation verifyAccountRequest($data: VerifyAccountRequestInput!) {\n    verifyAccountRequest(data: $data)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query canteenQuery($id: Int!) {\n    canteen(id: $id) {\n      id\n      name\n      region\n      city\n      slug\n      canteenSystemType\n      externalSystemId\n      lastOfferUpdateAt\n    }\n  }\n"): (typeof documents)["\n  query canteenQuery($id: Int!) {\n    canteen(id: $id) {\n      id\n      name\n      region\n      city\n      slug\n      canteenSystemType\n      externalSystemId\n      lastOfferUpdateAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query canteenOffersQuery($query: GetOffersInput!) {\n    canteenOffers(query: $query) {\n      id\n      date\n      food {\n        id\n        name\n        averageRating\n      }\n    }\n  }\n"): (typeof documents)["\n  query canteenOffersQuery($query: GetOffersInput!) {\n    canteenOffers(query: $query) {\n      id\n      date\n      food {\n        id\n        name\n        averageRating\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query canteensQuery($slug: String, $offset: Int, $limit: Int) {\n    canteens(query: {slug: $slug, offset: $offset, limit: $limit}) {\n      id\n      name\n      region\n      city\n      canteenSystemType\n      externalSystemId\n    }\n  }\n"): (typeof documents)["\n  query canteensQuery($slug: String, $offset: Int, $limit: Int) {\n    canteens(query: {slug: $slug, offset: $offset, limit: $limit}) {\n      id\n      name\n      region\n      city\n      canteenSystemType\n      externalSystemId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query commentsQuery($query: GetCommentsArgs!) {\n    comments(query: $query) {\n      id\n      text\n      date\n      ratingTaste\n      ratingVisual\n      ratingPreparation\n      food {\n        id\n        name\n      }\n      user {\n        userName\n      }\n    }\n  }\n"): (typeof documents)["\n  query commentsQuery($query: GetCommentsArgs!) {\n    comments(query: $query) {\n      id\n      text\n      date\n      ratingTaste\n      ratingVisual\n      ratingPreparation\n      food {\n        id\n        name\n      }\n      user {\n        userName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query foodQuery($id: Int!) {\n    food(id: $id) {\n      id\n      name\n      description\n      canteenId\n      averageRating\n      similarNames {\n        alternateName\n      }\n      photos {\n        id\n        s3url\n      }\n    }\n  }\n"): (typeof documents)["\n  query foodQuery($id: Int!) {\n    food(id: $id) {\n      id\n      name\n      description\n      canteenId\n      averageRating\n      similarNames {\n        alternateName\n      }\n      photos {\n        id\n        s3url\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query foodsQuery($canteenId: Int!, $offset: Int, $limit: Int, $search: String) {\n    foods(query: {canteenId: $canteenId, offset: $offset, limit: $limit, search: $search}) {\n      id\n      name\n      photos {\n        id\n        s3url\n      }\n    }\n  }\n"): (typeof documents)["\n  query foodsQuery($canteenId: Int!, $offset: Int, $limit: Int, $search: String) {\n    foods(query: {canteenId: $canteenId, offset: $offset, limit: $limit, search: $search}) {\n      id\n      name\n      photos {\n        id\n        s3url\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query foodsConflictsQuery($canteenId: Int!) {\n    foodsConflicts(canteenId: $canteenId) {\n      id\n      name\n      description\n      photos {\n        id\n        s3url\n      }\n    }\n  }\n"): (typeof documents)["\n  query foodsConflictsQuery($canteenId: Int!) {\n    foodsConflicts(canteenId: $canteenId) {\n      id\n      name\n      description\n      photos {\n        id\n        s3url\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query generateNewFoodQuery($tags: [String!]!) {\n    generateNewFood(tags: $tags) {\n      ingredients\n      name\n      recipe\n    }\n  }\n"): (typeof documents)["\n  query generateNewFoodQuery($tags: [String!]!) {\n    generateNewFood(tags: $tags) {\n      ingredients\n      name\n      recipe\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query generateMonthOffersQuery($canteenId: Int!, $date: DateTime!) {\n    generateMonthOffers(canteenId: $canteenId, date: $date) {\n      id\n      date\n      canteenId\n      food {\n        canteenId\n        id\n        name\n        tags {\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query generateMonthOffersQuery($canteenId: Int!, $date: DateTime!) {\n    generateMonthOffers(canteenId: $canteenId, date: $date) {\n      id\n      date\n      canteenId\n      food {\n        canteenId\n        id\n        name\n        tags {\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query photosQuery($foodId: Int,  $approved: Boolean) {\n    photos(query: {foodId: $foodId, approved: $approved}) {\n      id\n      s3url\n      food {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query photosQuery($foodId: Int,  $approved: Boolean) {\n    photos(query: {foodId: $foodId, approved: $approved}) {\n      id\n      s3url\n      food {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query userQuery($userId: Int!) {\n    user(id: $userId) {\n      id\n      userName\n      email\n      isEmailVerified\n      adminLevel\n      canteen {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query userQuery($userId: Int!) {\n    user(id: $userId) {\n      id\n      userName\n      email\n      isEmailVerified\n      adminLevel\n      canteen {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query usersQuery {\n    users {\n      id\n      email\n      isEmailVerified\n      adminLevel\n      userName\n      canteen {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query usersQuery {\n    users {\n      id\n      email\n      isEmailVerified\n      adminLevel\n      userName\n      canteen {\n        id\n        name\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;