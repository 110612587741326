import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router'

import OfferTable from './OfferTable'
import LoaderSpinner from '../../layout/loader'
import generateOffer from '../../queries/v2/generateOffer'
import Button from 'src/components/Button'
import { navy } from 'src/layout/colors'

const MenuMaker = () => {
  const { id, date: dateParam } = useParams<{ id: string; date: string }>()
  const navigate = useNavigate()

  const { loading, data, error } = useQuery(generateOffer, {
    variables: { canteenId: parseInt(id!), date: dateParam },
  })

  if (error) {
    console.log(error)
  }

  if (loading) {
    return <LoaderSpinner color="#ee982b" />
  }

  return (
    <Container100vh>
      <Container>
        <Inner>
          <Button onClick={() => navigate(-1)}>Vygenerovat znovu</Button>
          {!data || !data.generateMonthOffers.length ? (
            <EmptyMessage>Bohužel jsme nic neneašli :(</EmptyMessage>
          ) : (
            <OfferTable
              offer={data.generateMonthOffers}
              hideExpandButton
              disableFilter
              color="#ee982b"
            />
          )}
        </Inner>
      </Container>
    </Container100vh>
  )
}

export default MenuMaker

const EmptyMessage = styled.div``

const InputContainer = styled.div`
  display: flex;
  gap: 2rem;
  margin: 0 auto;
  width: fit-content;
  align-items: center;
`

const StyledSelect = styled.input``

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  background: url('/img/pattern_modry.svg'), ${navy};
  background-attachment: fixed;
  background-position: center;
  background-size: 730px;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  height: 100%;
`

const Inner = styled.div`
  text-align: center;
  background-color: white;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  width: 80%;
  margin: 10px auto;
  border-radius: 5px;
  padding: 20px 20px;
`
