import React from 'react'
import { useQuery } from '@apollo/client'
import { Navigate, useParams } from 'react-router-dom'

import canteens from 'src/queries/v2/canteens'
import LoaderSpinner from 'src/layout/loader'

import NotFound from '../NotFound'
import { routes } from 'src/constants/routes'

const CanteenRedirect: React.FC = () => {
  const { slug } = useParams<{ slug: string }>()
  const { data, loading } = useQuery(canteens, { variables: { limit: 1, slug: slug } })

  const canteen = data?.canteens.at(0)

  if (!slug) {
    return <NotFound />
  }

  if (loading) {
    return <LoaderSpinner />
  } else if (!canteen) {
    return <NotFound />
  }

  return <Navigate to={routes.canteenDetail(canteen.id)} />
}

export default CanteenRedirect
