import React from 'react'
import styled from 'styled-components'
import { Oval as LoaderOval } from 'react-loader-spinner'
import { backgroundGray, navy } from './colors'

interface Props {
  color?: string
  notAbsolute?: boolean
}

const LoaderSpinner = (props: Props) => {
  if (props.notAbsolute) {
    return (
      <LoaderOval color={props.color ?? navy} secondaryColor="#b4b4b4" height={100} width={100} />
    )
  }

  return (
    <Container>
      <LoaderOval color={props.color ?? navy} secondaryColor="#b4b4b4" height={150} width={150} />
    </Container>
  )
}

export default LoaderSpinner

const Container = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  align-items: center;
  background-color: ${backgroundGray};
  z-index: 999999;
  overflow: hidden;
`
