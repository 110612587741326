import { useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'

import canteenOfferHistory from 'src/queries/v2/canteenOfferHistory'

const useCanteenOfferForNextMonth = ({ canteenId }: { canteenId: string }) => {
  const curr = useMemo(() => new Date(), [])
  const [range] = useState({
    from: new Date().getTime(),
    to: new Date(curr.setDate(curr.getDate() - curr.getDay() + 31)).getTime(),
  })

  const res = useQuery(canteenOfferHistory, {
    variables: {
      query: {
        canteenId: parseInt(canteenId),
        from: range.from.toString(),
        to: range.to.toString(),
        order: 'ASC',
      },
    },
  })

  return res
}

export { useCanteenOfferForNextMonth }
