import React, { useEffect, useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useMutation } from '@apollo/client'
import { useParams, useNavigate } from 'react-router'

import { backgroundGray, navy } from '../../layout/colors'
import TextInput from '../../layout/textInput'
import forgotPasswordConfirm from '../../mutations/v2/forgotPasswordConfirm'
import { InputValueStateType } from '../../constants/types'
import { routes } from 'src/constants/routes'
import { ForgotPasswordConfirmInput } from 'src/__generated__/graphql'
import Button from 'src/components/Button'

const Main = () => {
  const navigate = useNavigate()
  const ref = useRef<HTMLButtonElement>(null)

  const params = useParams<{ userId: string; token: string }>()
  const [submitButtonInactive, setSubmitButtonInactive] = useState(true)
  const [err, setError] = useState(false)

  const [formState, setFormState] = useState<InputValueStateType<'password' | 'passwordAgain'>>({
    password: { value: '', isInvalid: false },
    passwordAgain: { value: '', isInvalid: false },
  })

  const [reg] = useMutation(forgotPasswordConfirm, {
    onCompleted() {
      navigate(routes.login)
    },
  })

  function handleSubmit() {
    if (
      formState.password.isInvalid ||
      formState.passwordAgain.isInvalid ||
      formState.password.value !== formState.passwordAgain.value
    ) {
      return setError(true)
    }
    const data: ForgotPasswordConfirmInput = {
      userId: params.userId ? parseInt(params.userId) : 0,
      password: formState.password.value,
      token: params.token ?? '',
    }
    setError(false)
    return reg({ variables: { data } })
  }

  useEffect(() => {
    if (formState.password.value !== formState.passwordAgain.value) {
      return setError(true)
    }
    return setError(false)
  }, [formState.passwordAgain.value, formState.password.value])

  useEffect(() => {
    if (
      formState.password.isInvalid ||
      formState.passwordAgain.isInvalid ||
      formState.password.value !== formState.passwordAgain.value
    )
      setSubmitButtonInactive(true)
    else setSubmitButtonInactive(false)
  }, [formState])

  function handleValidation(value: string, type: string) {
    if (type === 'password') {
      return !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value)
    }
    return false
  }

  function handleEnter(event: any) {
    if (event.keyCode === 13 && ref && ref.current) {
      event.preventDefault()
      ref.current.click()
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', handleEnter)

    return () => {
      window.removeEventListener('keyup', handleEnter)
    }
  }, [])

  return (
    <>
      <Container100vh>
        <Container>
          <LoginForm>
            <Heading>Obnova hesla</Heading>
            <TextInput
              type="password"
              placeholder="Heslo"
              value={formState.password.value}
              isInvalid={formState.password.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  password: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value, e.target.type),
                  },
                })
              }
            />
            <TextInput
              type="password"
              placeholder="Heslo znovu"
              value={formState.passwordAgain.value}
              isInvalid={formState.passwordAgain.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  passwordAgain: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value, e.target.type),
                  },
                })
              }
            />
            <div>{err && <LoginError>Hesla se neshodují</LoginError>}</div>
            <Button ref={ref} disabled={submitButtonInactive} onClick={handleSubmit}>
              Změnit heslo
            </Button>
          </LoginForm>
        </Container>
      </Container100vh>
    </>
  )
}

export default Main

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;

  background: url('/img/pattern_modry.svg'), ${navy};
  background-attachment: fixed;
  background-position: center;
  background-size: 730px;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  padding: 30px 0;
  height: 100%;
`

const LoginForm = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 50px 20px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  background-color: white;

  @media (min-width: 500px) {
    padding: 20px 50px 50px 50px;
    width: 70%;
  }

  @media (min-width: 600px) {
    width: 50%;
  }

  @media (min-width: 900px) {
    width: 30%;
  }

  @media (min-width: 2000px) {
    width: 20%;
  }

  @media (min-width: 3000px) {
    width: 15%;
  }
`

const Heading = styled.h2`
  text-align: center;
`

const LoginError = styled.span`
  color: #fc6969;
  font-weight: 500;
  font-size: 0.81em;
  position: absolute;
  margin-bottom: 0.75em;
  margin-top: -0.8em;
  display: flex;
  justify-content: flex-start;
`
