import React from 'react'
import { Navigate } from 'react-router-dom'
import Main from './Main'
import useLogin from '../../hooks/useLogin'
import { routes } from 'src/constants/routes'

const ForgotPassword = () => {
  const loginData = useLogin()
  if (loginData.user) {
    return <Navigate to={routes.main} />
  }
  return <Main />
}

export default ForgotPassword
