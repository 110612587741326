import * as S from './styled'

const Button = S.Button
const ButtonLink = S.ButtonLink
const ButtonA = S.ButtonA
const IconButton = S.IconButton

export { ButtonLink, ButtonA, IconButton }

export default Button
