import { media } from 'src/styles'
import styled from 'styled-components'

const Content = () => {
  return (
    <>
      <h1>Příběh jídla</h1>
      <Column>
        <BgImage src="/img/pribeh-jidla/cara.svg" />
        <Row>
          <TextContainer>
            <SmallHeading>Pole</SmallHeading>
            <Text>
              Na poli to všechno začíná. Jsou tu zasazeny třeba brambory, na jiném krmné kukuřice.
              Trvá to zhruba 100 dní než se může úroda sklidit, přičemž se pole v průběhu hnojí a
              ošetřuje. To tvoří další nároky, protože hnojivo musí někde vzniknout, postřiky musí
              někdo vyrobit a ještě k tomu rostliny potřebují spoustu vody aby mohly vyrůst a mít
              plody.
            </Text>
          </TextContainer>
          <div>
            <Image src="/img/pribeh-jidla/1.jpeg" />
          </div>
        </Row>
        <RowReverse>
          <TextContainer>
            <SmallHeading>Chovy</SmallHeading>
            <Text>
              Abychom na talři mohli mít maso, tak to taky musí někde vzniknout. K tomu aby kráva
              mohla z telátka vyrůst a nabrat svalovou hmotu kterou jíme, tak potřebuje vodu a něco
              k jídlu. Konkrétně na 1 kg hovězího masa je potřeba cca 40 kg krmiva a na to je třeba
              15 000 litrů vody. Toto krmivo pochází samozřejmě z polí, kde zabírá místo jiným
              plodinám, které by mohl jíst přímo člověk.
            </Text>
          </TextContainer>
          <div>
            <Image src="/img/pribeh-jidla/2.jpeg" />
          </div>
        </RowReverse>
        <Row>
          <TextContainer>
            <SmallHeading>Zpracování</SmallHeading>
            <Text>
              Abychom maso z té krávy mohli sníst, tak musí být převezena na jatka a zabita. Zde je
              maso taky dále zpracováno.
            </Text>
            <Text>
              U bezmasých potravin jako třeba těstovin nebo knedlíků probíhá zpracování v závodě, do
              kterého musí být dovezeny další suroviny pro výrobu a zajištěn přísun elektrické
              energie, která musí být zase někde vyrobena - její velký podíl v uhelných
              elektrárnách.
            </Text>
          </TextContainer>
          <div style={{ maxWidth: '300px' }}>
            <Image src="/img/pribeh-jidla/3.jpeg" />
          </div>
        </Row>
        <RowReverse>
          <TextContainer>
            <SmallHeading>Kuchyně</SmallHeading>
            <Text>
              Co se děje v kuchyni asi každý tuší, ale možná neví, že kuchařky a kuchaři vstávají
              okolo 4 ranní, aby vše stihly uvařit. Provoz ale taky není energeticky úplně nenáročný
              a je třeba si uvědomit, že pro uvaření jídla potřebujeme mimo jiné spoustu plynu na
              kterém se vaří. Nesmíme také zapomenout na dopravu z pole až sem, přičemž mražené
              výrobky musí být v chladících boxech které zase spotřebovávají energii.
            </Text>
          </TextContainer>
          <div>
            <Image src="/img/pribeh-jidla/4.jpeg" />
          </div>
        </RowReverse>
        <ColumnContent>
          <TextContainer>
            <SmallHeading>Talíř</SmallHeading>
            <div>
              <Image src="/img/pribeh-jidla/5.jpeg" />
            </div>
            <Text>
              Poté jídlo dostaneš u okénka na talíř a je jen na Tobě, jestli ho sníš a využiješ tak
              všechnu energii, která do něj byla nainvestovaná, anebo jestli jeho cesta bude
              pokračovat…
            </Text>
          </TextContainer>
        </ColumnContent>

        <Row>
          <TextContainer>
            <DesktopSpace />
            <SmallHeading>Kontejner</SmallHeading>
            <Text>
              Pokud jídlo u okénka vyhodíš, tak se ocitne v černé nádobě ze které pokračuje společně
              s dalšími zbytky do kontejneru. Není to ale běžný kontejner, je to kontejner na
              gastroodpad.
            </Text>
          </TextContainer>
          <div>
            <Image src="/img/pribeh-jidla/6.jpeg" />
          </div>
        </Row>
        <RowReverse>
          <TextContainer>
            <SmallHeading>Kafilerie</SmallHeading>
            <Text>
              Hodně lidí si myslí, že zbytky z jídelny jdou do koryta prasatům, ale tak to není.
              Podle zákona se musí kvůli možné kontaminaci separovat jako gastroodpad, který poté
              jde do kafilerie nebo bioplynové stanice. V té se částečně využije pro výrobu energie,
              ale v porovnání s tím, kolik jí do výroby jídla bylo investováno to je značně
              nevýhodná přeměna.
            </Text>
          </TextContainer>
          <div>
            <Image src="/img/pribeh-jidla/7.jpeg" />
          </div>
        </RowReverse>
      </Column>
    </>
  )
}

export default Content

const Svg = styled.img`
  width: 100%;
`

const BgImage = styled.img`
  position: absolute;
  height: 100%;

  top: 50%;
  left: 42%;
  transform: translate(-50%, -50%);
  z-index: 0;
  display: none;

  @media (min-width: 1000px) {
    display: block;
  }
`

const Column = styled.div`
  position: relative;
  /*  background-image: url('/img/pribeh-jidla/cara.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; */
`

const DesktopSpace = styled.div`
  display: none;

  ${media.sm`
    display: block;
    height: 140px;
  `}
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;

  @media (min-width: 700px) {
    flex-direction: row;
  }

  @media (min-width: 1000px) {
    gap: 20rem;
  }

  & > * {
    flex: 1;
  }
`

const RowReverse = styled(Row)`
  @media (min-width: 700px) {
    flex-direction: row-reverse;
  }
`

const Text = styled.p`
  position: relative;
  z-index: 1;
`

const SmallHeading = styled.h2``

const Image = styled.img`
  width: 100%;
`

const TextContainer = styled.div`
  flex: 1;
  text-align: left;
`

const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0;

  @media (min-width: 1000px) {
    margin-left: 25rem;
  }

  ${media.sm`
    * > p,
    * > h2 {
      text-align: center;
      max-width: 400px;
    }
    * > img {
      width: 400px;
      max-width: 100%;
      margin: 0 auto;
    }
    * > div {
      width: 400px;
      max-width: 100%;
      margin: 0 auto;
    }
  `}
`
