import { Link } from 'react-router-dom'
import { backgroundGray, navy } from 'src/layout/colors'
import styled, { css } from 'styled-components'

type LinkStylesProps = {
  disabled?: boolean
  variant?: 'primary' | 'outlined'
}

const LinkStyles = css<LinkStylesProps>`
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-decoration: none;
  padding: 10px 15px;
  font-size: 1rem;
  cursor: pointer;
  min-width: 200px;
  min-height: 48px;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      color: ${backgroundGray};
      background-color: rgb(204, 204, 204) !important;
      border-color: transparent !important;
    `}

  ${({ variant }) =>
    variant === 'outlined'
      ? css`
          color: black;
          background-color: transparent;
          border: 2px solid ${navy};

          &:hover {
            box-shadow: 0rem 0.5rem 1.5rem rgb(0 0 0 / 30%);
            background-color: #c2d2d8;
          }
        `
      : css`
          background-color: #005471;
          color: white;
          border: 2px solid #005471;

          &:hover {
            box-shadow: 0rem 0.5rem 1.5rem rgb(0 0 0 / 30%);
            background-color: #00384b;
            border-color: #00384b;
          }
        `}
`

export const ButtonLink = styled(Link)<LinkStylesProps>`
  ${LinkStyles};
`

export const ButtonA = styled.a<LinkStylesProps>`
  ${LinkStyles};
`

export const Button = styled.button<LinkStylesProps>`
  ${LinkStyles};
`

export const IconButton = styled.button`
  cursor: pointer;
  padding: 0;
  border: none;
  outline: 0;
  background: 0;

  &:hover {
    opacity: 0.7;
  }
`
