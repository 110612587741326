import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import { CanteenOffer } from 'src/queries/v2/canteenOfferHistory'
import Food from './Food'
import { backgroundGray, navy } from '../../layout/colors'
import useLogin from 'src/hooks/useLogin'
import { UserAdminLevel } from 'src/constants/constants'
import Button from 'src/components/Button'

interface Props {
  offer: Array<CanteenOffer>
  lastOfferUpdateAt: Date | null | undefined
  canteenId: number
  hideExpandButton?: boolean
  disableFilter?: boolean
  color?: string
  refetchQueries?: () => void
}

function convertDate(dateString: string) {
  const date = new Date(dateString)
  return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
}

const days = ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota', 'Neděle']

const OfferTable = ({
  offer,
  canteenId,
  hideExpandButton,
  disableFilter,
  color,
  refetchQueries,
  lastOfferUpdateAt,
}: Props) => {
  const curr = new Date()
  const [endDate, setToDate] = useState(() => {
    const curr = new Date()
    return new Date(curr.setDate(curr.getDate() + 7))
  })
  const { user } = useLogin()

  const displayed = useMemo(() => {
    if (disableFilter) {
      return offer
    }
    return offer.filter((offer) => {
      const date = new Date(offer.date)
      return date <= endDate
    })
  }, [offer, endDate, disableFilter])

  const increaseWeek = () => {
    const newEndDate = new Date(endDate)
    newEndDate.setDate(newEndDate.getDate() + 7)
    setToDate(newEndDate)
  }

  const canDeleteFood = !!(
    user &&
    (user.canteenId === canteenId || user.adminLevel === UserAdminLevel.SUPERADMIN)
  )

  if (displayed.length === 0) {
    return <p>Jídelníček na tento týden se nepodařilo najít</p>
  }

  return (
    <>
      <Table>
        {displayed.map((offer, index) => {
          return (
            <React.Fragment key={offer.id}>
              <TableHead>
                <Th color={color}>Datum</Th>
                <Th color={color}>
                  {days.at(new Date(offer.date).getDay())} {convertDate(offer.date)}
                </Th>
                {canDeleteFood && <Th color={color}></Th>}
              </TableHead>
              <Food
                isLastDay={new Date(offer.date).getDay() === 5}
                isLast={index + 1 === displayed.length}
                food={offer.food}
                offerId={offer.id}
                canDeleteFood={canDeleteFood}
                color={color}
                refetchQueries={refetchQueries}
              />
            </React.Fragment>
          )
        })}
      </Table>
      {canDeleteFood && lastOfferUpdateAt && (
        <MuttedText>
          Poslední aktualizace jídelníčku: {lastOfferUpdateAt.toLocaleString()}
        </MuttedText>
      )}

      {!hideExpandButton && (
        <ShowMoreButtonWrap>
          <Button
            onClick={() => increaseWeek()}
            color={color}
            disabled={displayed.length === offer.length}
          >
            Zobrazit více
          </Button>
        </ShowMoreButtonWrap>
      )}
    </>
  )
}

export default OfferTable

const Table = styled.table`
  text-align: left;
  background-color: white;
  border-collapse: collapse;
  width: 100%;
`

const TableHead = styled.tr`
  background-color: ${backgroundGray};
`

interface TableHeadProps {
  color?: string
}

const Th = styled.th<TableHeadProps>`
  padding: 10px;
  color: white;
  background-color: ${(props) => props.color || navy};
`

const MuttedText = styled.p`
  color: #666;
  font-size: 0.8rem;
  text-align: left;
`

const ShowMoreButtonWrap = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
