import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Modal } from '@mui/material'

import resolveFoodConflict from 'src/mutations/v2/resolveFoodConflict'
import { FoodInConflict } from 'src/queries/v2/foodsConflicts'
import { InputValueStateType } from 'src/constants/types'
import TextInput, { TextArea } from 'src/layout/textInput'

import * as S from './styled'
import Button from 'src/components/Button'

type Props = {
  open: boolean
  onClose: () => void
  foodA: FoodInConflict
  foodB: FoodInConflict
  onCompleted: () => void
}

const MergeModal: React.FC<Props> = ({ onCompleted, onClose, open, foodA, foodB }) => {
  const [formState, setFormState] = useState<InputValueStateType<'name' | 'description'>>({
    name: { value: foodA.name || foodB.name, isInvalid: false },
    description: { value: foodA.description || foodB.description, isInvalid: false },
  })

  const [req, status] = useMutation(resolveFoodConflict, {
    onCompleted: () => {
      onCompleted()
    },
  })

  const handleSubmit = () => {
    req({
      variables: {
        data: {
          foodToKeepId: foodA.id,
          foodToDeleteId: foodB.id,
          newName: formState.name.value ?? '',
          newDescription: formState.description.value ?? '',
        },
      },
      onCompleted: () => {
        onCompleted()
        onClose()
      },
    })
  }

  function handleValidation(value: string) {
    return false
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <S.LoginForm>
        <S.Heading>
          Spojení jídel
          <br />
        </S.Heading>
        <S.InfoText>
          Hodnocení obou jídle budou spojeny. Jídlo bude nově uloženo pod tímto názvem.
        </S.InfoText>
        <TextInput
          type="text"
          placeholder="Název jídla"
          name="text"
          value={formState.name.value}
          isInvalid={formState.name.isInvalid}
          onChange={(e) =>
            setFormState({
              ...formState,
              name: {
                value: e.target.value,
                isInvalid: handleValidation(e.target.value),
              },
            })
          }
        />
        <TextArea
          placeholder="Popis jídla"
          value={formState.description.value}
          isInvalid={formState.description.isInvalid}
          onChange={(e) =>
            setFormState({
              ...formState,
              description: {
                value: e.target.value,
                isInvalid: handleValidation(e.target.value),
              },
            })
          }
        />
        <br />
        <Button onClick={onClose} variant="outlined">
          Zrušit
        </Button>
        <br />
        <Button onClick={handleSubmit} disabled={!!status.loading}>
          {!!status.loading ? 'Ukládání...' : 'Provést změny'}
        </Button>
      </S.LoginForm>
    </Modal>
  )
}

export default MergeModal
