import { Route, Routes } from 'react-router-dom'

import Home from './screens/home'
import Login from './screens/login'
import Canteen from './screens/canteen/Main'
import Header from './layout/Header'
import NoOverflowContainer from './layout/NoOverflowContainer'
import Footer from './layout/Footer'
import AddCanteen from './screens/addCanteen/Main'
import Register from './screens/register'
import NotFound from './screens/NotFound'
import LoginRoute from './Routes/LoginRoute'
import Users from './screens/users'
import CustomAuthorizedRoute from './Routes/CustomAuthorizedRoute'
import EditUser from './screens/editUser'
import ForgotPassword from './screens/forgotPassword'
import RenewPassword from './screens/renewPassword'
import CanteensAdmin from './screens/canteensAdmin'
import Food from './screens/food/Main'
import AddPhoto from './screens/addPhoto/Main'
import EditCanteen from './screens/editCanteen'
import FoodAdmin from './screens/foodAdmin'
import EditFood from './screens/editFood'
import PhotoAdmin from './screens/photoAdmin'
import AddOffer from './screens/addOffer/Main'
import AddFood from './screens/addFood/Main'
import About from './screens/about/Main'
import HowItWorks from './screens/howItWorks/Main'
import History from './screens/history/Main'
import MenuMaker from './screens/menuMaker'
import FoodWaste from './screens/foodWaste/Main'
import MenuMakerForm from './screens/menuMakerForm'
import ScrollToTop from './components/ScrollToTop'
import { UserAdminLevel } from './constants/constants'
import { routes } from './constants/routes'
import CommentsAdmin from './screens/commentsAdmin'
import FoodAdminDuplicates from './screens/foodAdminDuplicates'
import VerifyAccount from './screens/verifyAccount'
import CanteenRedirect from './screens/canteenRedirect'

function App() {
  return (
    <NoOverflowContainer>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route
          path={routes.login}
          element={
            <LoginRoute>
              <Login />
            </LoginRoute>
          }
        />
        <Route
          element={
            <CustomAuthorizedRoute adminLevel={UserAdminLevel.SUPERADMIN}>
              <Users />
            </CustomAuthorizedRoute>
          }
          path={routes.users}
        />
        <Route
          element={
            <CustomAuthorizedRoute adminLevel={UserAdminLevel.SUPERADMIN}>
              <EditUser />
            </CustomAuthorizedRoute>
          }
          path={routes.usersDetailEdit(':id')}
        />
        <Route
          element={
            <CustomAuthorizedRoute adminLevel={UserAdminLevel.USER}>
              <EditUser />
            </CustomAuthorizedRoute>
          }
          path={routes.editUser}
        />
        <Route path={routes.forgotPassword} element={<ForgotPassword />} />
        <Route path={routes.verifyAccount} element={<VerifyAccount />} />
        <Route path={routes.about} element={<About />} />
        <Route path={routes.howItWorks} element={<HowItWorks />} />
        <Route path={routes.renewPassword} element={<RenewPassword />} />
        <Route path={routes.login} element={<Login />} />
        <Route path={routes.register} element={<Register />} />

        <Route
          element={
            <CustomAuthorizedRoute adminLevel={UserAdminLevel.SUPERADMIN}>
              <EditCanteen />
            </CustomAuthorizedRoute>
          }
          path={routes.canteenEdit(':id')}
        />

        <Route path={routes.canteenDetailHistory(':id')} element={<History />} />
        <Route
          element={
            <CustomAuthorizedRoute adminLevel={UserAdminLevel.ADMIN}>
              <FoodAdmin />
            </CustomAuthorizedRoute>
          }
          path={routes.canteenDetailFood(':id')}
        />
        <Route
          element={
            <CustomAuthorizedRoute adminLevel={UserAdminLevel.ADMIN}>
              <CommentsAdmin />
            </CustomAuthorizedRoute>
          }
          path={routes.canteenDetailComments(':id')}
        />
        <Route path={routes.canteenDetail(':id')} element={<Canteen />} />
        <Route path={routes.foodWaste} element={<FoodWaste />} />
        <Route
          element={
            <CustomAuthorizedRoute adminLevel={UserAdminLevel.SUPERADMIN}>
              <AddCanteen />
            </CustomAuthorizedRoute>
          }
          path={routes.canteenAdd}
        />
        <Route
          element={
            <CustomAuthorizedRoute adminLevel={UserAdminLevel.SUPERADMIN}>
              <CanteensAdmin />
            </CustomAuthorizedRoute>
          }
          path={routes.canteens}
        />
        <Route
          element={
            <CustomAuthorizedRoute adminLevel={UserAdminLevel.USER}>
              <AddPhoto />
            </CustomAuthorizedRoute>
          }
          path={routes.foodDetailAddPhoto(':id')}
        />
        <Route
          element={
            <CustomAuthorizedRoute adminLevel={UserAdminLevel.ADMIN}>
              <EditFood />
            </CustomAuthorizedRoute>
          }
          path={routes.foodDetailEdit(':id')}
        />
        <Route path={routes.foodDetail(':id')} element={<Food />} />
        <Route
          element={
            <CustomAuthorizedRoute adminLevel={UserAdminLevel.ADMIN}>
              <FoodAdminDuplicates />
            </CustomAuthorizedRoute>
          }
          path={routes.canteenDetailFoodDuplicates(':id')}
        />
        <Route
          element={
            <CustomAuthorizedRoute adminLevel={UserAdminLevel.ADMIN}>
              <AddOffer />
            </CustomAuthorizedRoute>
          }
          path={routes.canteenDetailOfferAdd(':id')}
        />
        <Route
          element={
            <CustomAuthorizedRoute adminLevel={UserAdminLevel.ADMIN}>
              <AddFood />
            </CustomAuthorizedRoute>
          }
          path={routes.canteenDetailFoodAdd(':id')}
        />
        <Route
          element={
            <CustomAuthorizedRoute adminLevel={UserAdminLevel.ADMIN}>
              <PhotoAdmin />
            </CustomAuthorizedRoute>
          }
          path={routes.photos}
        />
        <Route
          element={
            <CustomAuthorizedRoute adminLevel={UserAdminLevel.ADMIN}>
              <MenuMakerForm />
            </CustomAuthorizedRoute>
          }
          path={'/menuMakerForm/:id'}
        />
        <Route
          element={
            <CustomAuthorizedRoute adminLevel={UserAdminLevel.ADMIN}>
              <MenuMaker />
            </CustomAuthorizedRoute>
          }
          path="/menuMaker/:id/:date"
        />
        <Route path={routes.main} element={<Home />} />
        <Route path={routes.canteenDetailNiceUrl(':slug')} element={<CanteenRedirect />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </NoOverflowContainer>
  )
}

export default App
