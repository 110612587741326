import React from 'react'
import { navy } from 'src/layout/colors'
import styled, { css } from 'styled-components'

const About = () => {
  return (
    <Container100vh>
      <Content>
        <MainHeading>O nás</MainHeading>
        <TextWrapper>
          <Text>
            <b>Kontakt: </b>
            <Href href="mailto:info@natacu.cz">info@natacu.cz</Href>
          </Text>
        </TextWrapper>

        <Wrapper reversed={true}>
          <ImgWrapper>
            <Img src="/img/about/pie.webp" alt="" />
          </ImgWrapper>
          <TextWrapper>
            <Heading>Ondřej Pipek</Heading>
            <SubHeading>„Tahoun co vidí cíl”</SubHeading>
            <Text>
              Stál u zrodu myšlenky a snažil se ji zrealizovat. Povedlo se mu k tomu sehnat potřebné
              lidi a nejednu soutěž, díky které mohl náš projekt zazářit a získat podporu.
              Momentálně obchází jídelny a vysvětluje tam, proč je náš projekt tak strašně super,
              což se mu celkem daří.
            </Text>
          </TextWrapper>
        </Wrapper>

        <Wrapper>
          <ImgWrapper>
            <Img src="/img/about/matej.webp" alt="" />
          </ImgWrapper>
          <TextWrapper>
            <Heading>Matěj Rychnovský</Heading>
            <SubHeading>„Tahoun co se kouká na cestu”</SubHeading>
            <Text>
              K projektu se přidal v samotných začátcích, od té doby mu pomáhá udávat směr. Protože
              je velký detailista, tak řeší většinu právních věcí, ale i spoustu neprávních věcí,
              kde je detail potřeba. Vyžívá se v grafice, komunikaci a neskrývá ambice dobýt
              projektem svět.
            </Text>
          </TextWrapper>
        </Wrapper>

        <TextWrapper>
          <Heading>Spousta dalších</Heading>
          <Text>
            Chtěli bychom taky poděkovat všem, kteří se na projektu jakkoliv podíleli, protože jich
            bylo opravdu hodně - od studentů naší střední školy Gymnázia Dašická, kteří v začátcích
            pomáhali fotit jídla, přes lidi, kteří se na určitou dobu stali součástí týmu a pomohli
            rozvinout myšlenku, a mnohým dalším. Velký dík za vstřícnost k projektu patří také
            skvělým kuchařkám naší jídelny.
          </Text>
          <Text>
            Zvláštní díky patří taky Vojtovi Šišmovi, který v počátcích vytvořil webovou aplikaci a
            pomohl tím nastartovat celý projekt. Od léta 2023 se rozhodl věnovat více jiným
            projektům, což respektujeme a přejeme hodně štěstí.
          </Text>
        </TextWrapper>

        <MainHeading>Naše úspěchy</MainHeading>
        <TextWrapper>
          <Heading>
            Soutěž{' '}
            <Href
              target="_blank"
              href="https://eutis.cz/konference-zakoncila-evropsky-tyden-mladeze-a-workshopy-budoucnost-mame-ve-svych-rukou/"
            >
              Budoucnost máme ve svých rukou
            </Href>
          </Heading>
          <Text>
            S naším projektem jsme se zúčastnili soutěže Budoucnost máme ve svých rukou, přičemž
            jsme se v celorepublikovém finále umístili na 2. místě. Napsali jsme k tomu i
            krátký&nbsp;
            <Href
              target="_blank"
              href="https://www.gypce.cz/2021/06/25/workshop-budoucnost-mame-ve-svych-rukou-pokracovani/"
            >
              článek
            </Href>
            .
          </Text>
        </TextWrapper>
        <ImagesGrid variant="2_1_2">
          <GridImg>
            <img src="/img/about/budoucnost_1.webp" alt="Budoucnost máme ve svých rukou" />
          </GridImg>
          <GridImg>
            <img src="/img/about/budoucnost_2.webp" alt="Budoucnost máme ve svých rukou" />
          </GridImg>
          <GridImg>
            <img src="/img/about/budoucnost_3.webp" alt="Budoucnost máme ve svých rukou" />
          </GridImg>
        </ImagesGrid>

        <TextWrapper>
          <Heading>
            Soutěž{' '}
            <Href target="_blank" href="https://nakopniprahu.cz/">
              Nakopni Prahu
            </Href>
          </Heading>
          <Text>
            Zjistili jsme, že by projekt mohl fungovat, tak jsme se zúčastnili inovačního maratonu
            Nakopni Prahu. Projekt se líbil - v náročné konkurenci jsme obsadili 2. místo, získali
            podporu od Operátora ICT a vydali se dobývat pražské školní jídelny.
          </Text>
        </TextWrapper>
        <ImagesGrid variant="1_1_1">
          <GridImg>
            <img src="/img/about/nakopni_prahu_1.webp" alt="Nakopni Prahu" />
          </GridImg>
          <GridImg>
            <img src="/img/about/nakopni_prahu_2.webp" alt="Nakopni Prahu" />
          </GridImg>
          <GridImg>
            <img src="/img/about/nakopni_prahu_3.webp" alt="Nakopni Prahu" />
          </GridImg>
        </ImagesGrid>

        <TextWrapper>
          <Heading>
            Soutěž{' '}
            <Href target="_blank" href="https://greenhack.eu/">
              GreenHack
            </Href>
          </Heading>
          <Text>
            Po Nakopni Prahu jsme byli naladěni na úspěšnou vlnu a vrhli se do další soutěže -
            největší inovační akce na téma udržitelnosti ve střední Evropě - hackatonu Greenhack.
            Tam jsme se konkurence báli možná ještě více než dříve, ale o to víc nás překvapilo
            krásné 3. místo.
          </Text>
        </TextWrapper>
        <ImagesGrid variant="1_1_1">
          <GridImg>
            <img src="/img/about/greenhack_1.webp" alt="Greenhack" />
          </GridImg>
          <GridImg>
            <img src="/img/about/greenhack_2.webp" alt="Greenhack" />
          </GridImg>
          <GridImg>
            <img src="/img/about/greenhack_3.webp" alt="Greenhack" />
          </GridImg>
        </ImagesGrid>

        <TextWrapper>
          <Heading>
            Účast na konferenci{' '}
            <Href
              target="_blank"
              href="https://www.tretiruka.cz/media-a-odpady/predchazeni-vzniku-odpadu/"
            >
              Předcházení vzniku odpadů 2023
            </Href>
          </Heading>
          <Text>
            Díky zviditelnění našeho projektu v soutěžích jsme dostali nabídku prezentovat náš
            projekt na konferenci Předcházení vzniku odpadů 2023. Připravili jsme si 15minutový
            příspěvek s názvem „Ve školní jídelně“, kde jsme rozebírali problematiku vzniku odpadu v
            jídelnách, jak s tím souvisí vyhláška o školním stravování a co jsme se s tím rozhodli
            dělat. Na základě konference jsme dostali několik pozitivních reakcí a nabídek, což
            nejspíš značí, že se nám výstup podařil.
          </Text>
        </TextWrapper>
        <ImagesGrid variant="1_1">
          <GridImg>
            <img src="/img/about/pvo-konference_1.webp" alt="PVO 2023" />
          </GridImg>
          <GridImg>
            <img src="/img/about/pvo-konference_2.webp" alt="PVO 2023" />
          </GridImg>
        </ImagesGrid>

        <MainHeading>Kde jste se o nás mohli dočíst</MainHeading>
        <TextWrapper>
          <h3>GreenHack</h3>
          {[
            'https://sj.news/inovacni-hackathon-greenhack-vyhral-mezinarodni-tym-etickych-hackeru-jejich-krabicka-nauci-domacnosti-setrit-energii/',
          ].map((link) => (
            <LinkWrap key={link}>
              <Href target="_blank" href={link}>
                {link}
              </Href>
            </LinkWrap>
          ))}

          <h3>Nakopni Prahu</h3>
          {[
            'https://www.youtube.com/watch?v=Opc4iJnlKXw&ab',
            'https://www.blesk.cz/clanek/regiony-praha-praha-zpravy/744405/kamerky-u-kontejneru-hezka-skolni-jidla-a-pohodlna-bezbarierova-navigace-projekty-ktere-maji-nakopnout-prahu.html',
            'https://www.prahain.cz/zivot-ve-meste/vitezny-projekt-inovacniho-maratonu-nakopni-prahu-pomuze-s-uklidem-ulic-13024.html',
            'https://www.novinykraje.cz/blog/2023/05/30/ai-senzor-pomuze-s-uklidem-ulic-databaze-zakum-s-vyberem-jidla-inovacni-maraton-zna-viteze',
            'https://sj.news/prahu-ma-nakopnout-ai-senzor-pro-uklid-ulic-uspel-i-web-pro-skolni-jidelny-ci-aplikace-usnadnujici-pohyb/',
            'https://www.nasepraha.cz/inovacni-maraton-nakopni-prahu-vyhral-ai-senzor-pro-uklid-ulic/',
            'https://www.prazskypatriot.cz/nakopni-prahu-soutez-pro-inovatory-vyhral-senzor-pro-uklid-ulic/',
            'https://www.businessinfo.cz/clanky/inovacni-maraton-nakopni-prahu-vyhral-ai-senzor-pro-uklid-ulic/',
            'https://www.nasepraha.cz/inovacni-maraton-nakopni-prahu-zna-jmena-finalistu/',
          ].map((link) => (
            <LinkWrap key={link}>
              <Href target="_blank" href={link}>
                {link}
              </Href>
            </LinkWrap>
          ))}

          <h3>Gymnázium Pardubice Dašická</h3>
          {['https://www.gypce.cz/2024/02/12/na-tacu-aneb-databaze-jidel-v-novem-kabate/'].map(
            (link) => (
              <LinkWrap key={link}>
                <Href target="_blank" href={link}>
                  {link}
                </Href>
              </LinkWrap>
            ),
          )}
        </TextWrapper>
      </Content>
    </Container100vh>
  )
}

export default About

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  background: url('/img/pattern_modry.svg'), ${navy};
  background-attachment: fixed;
  background-position: center;
  background-size: 730px;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Content = styled.div`
  text-align: center;
  background-color: white;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  width: 80%;
  margin: 10px auto;
  border-radius: 5px;
  padding: 20px 5rem;
  max-width: 60rem;

  @media (max-width: 900px) {
    padding: 20px 2rem;
  }
`

const Wrapper = styled.div<{ reversed?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0;

  ${({ reversed }) =>
    reversed
      ? css`
          flex-direction: row-reverse;

          & > div:nth-child(2) {
            padding-right: 2.5rem;
          }
        `
      : css`
          & > div:nth-child(2) {
            padding-left: 2.5rem;
          }
        `}

  @media (max-width: 900px) {
    flex-direction: column !important;
    text-align: justify;

    & > div:nth-child(2) {
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }
`

const TextWrapper = styled.div`
  flex: 2;
  text-align: left;

  @media (max-width: 900px) {
    text-align: justify;
  }

  h3 {
    margin-top: 2rem;
  }
`

const MainHeading = styled.h1``

const Heading = styled.h2`
  margin-bottom: 0.25rem;
`

const SubHeading = styled.p`
  color: #424242;
  margin-top: 0;
`

const Text = styled.p``

const Img = styled.img`
  flex: 1;
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  object-fit: cover;
`

const ImgWrapper = styled.div``

const Href = styled.a`
  color: black;
`

const ImagesGrid = styled.div<{ variant: '1_1' | '1_1_1' | '2_1_2' }>`
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: ${({ variant }) =>
    variant === '1_1' ? '1fr 1fr' : variant === '1_1_1' ? '1fr 1fr 1fr' : '2fr 1fr 2fr'};
  margin: 2rem 0;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`

const GridImg = styled.div`
  > img {
    width: 100%;
    height: 17rem;
    object-fit: cover;
  }

  @media (max-width: 900px) {
    > img {
      height: 100%;
      object-fit: cover;
      max-width: 22rem;
    }
  }
`

const LinkWrap = styled.div`
  margin: 16px 0;
`
