import React from 'react'
import styled from 'styled-components'
import { Navigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Grid } from '@mui/material'

import comments from '../../queries/v2/comments'
import LoaderSpinner from '../../layout/loader'
import Comment from './Comment'

interface Props {
  foodId: number
  userCanEditComment: boolean
}

const Comments = ({ foodId, userCanEditComment }: Props) => {
  const { loading, error, data } = useQuery(comments, {
    variables: { query: { foodId } },
  })

  if (error) {
    return <Navigate to={`/`} />
  }

  if (loading) {
    return <LoaderSpinner />
  }

  return (
    <Container>
      <h2>Hodnocení ostatních strávníků</h2>
      <CommentsSection>
        {data?.comments.length === 0 && <p>Jídlo zatím nebylo hodnoceno</p>}
        <Grid container spacing={2} justifyContent="center">
          {data?.comments?.map((comment) => (
            <Grid item key={comment.id} xs={12} md={6} lg={4}>
              <Comment
                comment={comment}
                foodId={foodId}
                canteenId={undefined}
                userCanEditComment={userCanEditComment}
              />
            </Grid>
          ))}
        </Grid>
      </CommentsSection>
    </Container>
  )
}

export default Comments

const Container = styled.div`
  margin-top: 32px;
`

const CommentsSection = styled.div`
  max-width: 50vw;
  margin: 0 auto;
`
