import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { media } from 'src/styles'
import { navy } from 'src/layout/colors'

export const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  background: url('/img/pattern_modry.svg'), ${navy};
  background-attachment: fixed;
  background-position: center;
  background-size: 730px;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

export const Container = styled.div`
  flex: 1;
  height: 100%;
`

export const Inner = styled.div`
  text-align: center;
  background-color: white;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  width: 80%;
  margin: 10px auto;
  border-radius: 5px;
  padding: 20px 20px;
  box-sizing: border-box;

  ${media.maxSm`
    width: 96%;
    padding-left: 8px;
    padding-right: 8px;
  `}
`

export const AddPhoto = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: white;
  text-decoration: none;
  margin: 0 auto;
`

export const ButtonsWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px 0;
  gap: 1rem;

  & > * {
    min-width: auto;
  }
`

export const OfferHeadline = styled.h2`
  margin-top: 50px;
`
