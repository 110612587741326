import React from 'react'
import styled from 'styled-components'
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  CameraAlt as CameraAltIcon,
} from '@mui/icons-material'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router'
import { useConfirm } from 'material-ui-confirm'

import { backgroundGray, navy } from '../../layout/colors'
import { FoodType } from '../canteen/Food'
import removeFood from '../../mutations/v2/removeFood'
import foods from '../../queries/v2/foods'
import { routes } from 'src/constants/routes'
import { IconButton } from 'src/components/Button'

interface Props {
  canteenId: number
  item: FoodType
}

const Main = ({ item, canteenId }: Props) => {
  const confirm = useConfirm()
  const navigate = useNavigate()

  const refetchQueries = {
    refetchQueries: [
      {
        query: foods,
        variables: { canteenId },
      },
    ],
  }
  const [req] = useMutation(removeFood, {
    ...refetchQueries,
  })

  function handleDelete() {
    confirm({
      title: `Smazat ${item.name}?`,
      description: `Tato akce je nevratná.`,
      cancellationText: 'Zrušit',
    }).then(() => req({ variables: { id: item.id } }))
  }

  return (
    <Row>
      <Td>{item.name}</Td>
      <Td>
        <IconButton onClick={() => navigate(routes.foodDetail(item.id))}>
          <Camera />
        </IconButton>
      </Td>
      <Td>
        <IconButton onClick={() => navigate(routes.foodDetailEdit(item.id))}>
          <Edit />
        </IconButton>
      </Td>
      <Td>
        <IconButton
          onClick={() => {
            handleDelete()
          }}
        >
          <Delete />
        </IconButton>
      </Td>
    </Row>
  )
}

export default Main

const Row = styled.tr`
  &:nth-child(odd) {
    background-color: ${backgroundGray};
  }

  &:hover {
    background-color: #e9e9e9;
  }
`

const Td = styled.td`
  padding: 5px;

  &:nth-last-child(1) {
    text-align: right;
    width: 34px;
  }
  &:nth-last-child(2) {
    text-align: right;
    width: 34px;
  }
  &:nth-last-child(3) {
    text-align: right;
  }
`

const Edit = styled(EditIcon)`
  color: ${navy};
`

const Delete = styled(DeleteIcon)`
  color: ${navy};
`

const Camera = styled(CameraAltIcon)`
  color: ${navy};
`
