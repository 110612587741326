import { gql } from 'src/__generated__'
import { FoodsConflictsQueryQuery } from 'src/__generated__/graphql'

export type FoodInConflict = FoodsConflictsQueryQuery['foodsConflicts'][0][0]

export default gql(`
  query foodsConflictsQuery($canteenId: Int!) {
    foodsConflicts(canteenId: $canteenId) {
      id
      name
      description
      photos {
        id
        s3url
      }
    }
  }
`)
