import { useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'
import { Oval as OvalLoader } from 'react-loader-spinner'
import styled from 'styled-components'

import generateNewFood from 'src/queries/v2/generateNewFood'
import Button from 'src/components/Button'

interface Props {
  tags: string[]
}

const GenerateAi = (props: Props) => {
  const [getFood, { loading, data, error }] = useLazyQuery(generateNewFood, {
    variables: { tags: props.tags },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    getFood()
  }, [])

  return (
    <>
      <h3>Vygenerovat nový recept</h3>
      {loading && (
        <Loading>
          <OvalLoader color={'#ee982b'} height={50} width={50} />
          Generujeme unikátní recept, může to chvíli trvat
        </Loading>
      )}
      {data?.generateNewFood && (
        <>
          <div>
            <p>Název: {data.generateNewFood.name}</p>
            <div>
              <p>Ingredience:</p>
              <ul>
                {data.generateNewFood.ingredients.map((ingredient: any) => (
                  <li>{ingredient}</li>
                ))}
              </ul>
            </div>
            <p>Postup: {data.generateNewFood.recipe}</p>
          </div>
          <Button
            onClick={() => {
              console.log('click')
              getFood()
            }}
          >
            Vygenerovat znovu
          </Button>
        </>
      )}
    </>
  )
}

export default GenerateAi

const Loading = styled.div`
  text-align: center;
`
