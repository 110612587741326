import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'

import TextInput from '../../layout/textInput'
import forgotPasswordRequest from '../../mutations/v2/forgotPasswordRequest'
import { InputValueStateType } from '../../constants/types'
import Button from 'src/components/Button'
import { navy } from 'src/layout/colors'

const Main = () => {
  const [submitButtonInactive, setSubmitButtonInactive] = useState(true)
  const [message, setMessage] = useState(false)
  const [formState, setFormState] = useState<InputValueStateType<'email'>>({
    email: { value: '', isInvalid: false },
  })

  const [forgotPass] = useMutation(forgotPasswordRequest)

  function handleSubmit() {
    if (formState.email.isInvalid) {
      return setFormState({
        ...formState,
        email: {
          value: formState.email.value,
          isInvalid: true,
        },
      })
    }
    const email = formState.email.value
    return forgotPass({
      variables: { data: { email } },
      onCompleted: () => {
        setMessage(true)
        setFormState({
          ...formState,
          email: {
            value: '',
            isInvalid: false,
          },
        })
      },
    })
  }

  useEffect(() => {
    if (formState.email.isInvalid || formState.email.value.length == 0)
      setSubmitButtonInactive(true)
    else setSubmitButtonInactive(false)
  }, [formState])

  function handleValidation(value: string, type: string) {
    if (type === 'email') {
      return !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value,
      )
    }
    return false
  }

  return (
    <>
      <Container100vh>
        <Container>
          <LoginForm>
            <Heading>Obnova hesla</Heading>
            <TextInput
              type="email"
              placeholder="Váš email"
              name="email"
              value={formState.email.value}
              isInvalid={formState.email.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  email: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value, e.target.type),
                  },
                })
              }
            />{' '}
            <div>
              {message && (
                <LoginError>Email byl odeslán, pokračujte kliknutím na odkaz v emailu</LoginError>
              )}
            </div>
            <Button disabled={submitButtonInactive} onClick={handleSubmit}>
              Zaslat obnovení na email
            </Button>
          </LoginForm>
        </Container>
      </Container100vh>
    </>
  )
}

export default Main

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;

  background: url('/img/pattern_modry.svg'), ${navy};
  background-attachment: fixed;
  background-position: center;
  background-size: 730px;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  padding: 30px 0;
  height: 100%;
`

const LoginForm = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 50px 20px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  background-color: white;

  @media (min-width: 500px) {
    padding: 20px 50px 50px 50px;
    width: 70%;
  }

  @media (min-width: 600px) {
    width: 50%;
  }

  @media (min-width: 900px) {
    width: 30%;
  }

  @media (min-width: 2000px) {
    width: 20%;
  }

  @media (min-width: 3000px) {
    width: 15%;
  }
`

const Heading = styled.h2`
  text-align: center;
`

const LoginError = styled.span`
  font-weight: 500;
  font-size: 0.81em;
  position: absolute;
  margin-bottom: 0.75em;
  margin-top: -0.8em;
  display: flex;
  justify-content: flex-start;
`
