import React from 'react'
import { Navigate } from 'react-router-dom'
import { routes } from 'src/constants/routes'
import Main from './Main'
import useLogin from '../../hooks/useLogin'

const EditFood = () => {
  const loginData = useLogin()
  if (!loginData.user) {
    return <Navigate to={routes.login} />
  }

  const userId = loginData.user.id
  return <Main userId={userId} />
}

export default EditFood
