import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink } from '@apollo/client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'

import { ConfirmProvider } from 'material-ui-confirm'
import { ConfigProvider } from 'antd'
import ReactGA from 'react-ga4'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { resolvers, typeDefs } from './resolvers'
import { GlobalStyle } from './styles/globalStyles'

if (process.env.REACT_APP_GA4_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA4_ID)
}

const errorLink = onError(({ graphQLErrors, networkError }): any => {
  const ignoredErrors = ['Bad credentials']
  // @ts-ignore
  if (graphQLErrors && graphQLErrors[0].message) {
    // @ts-ignore
    if (
      graphQLErrors[0].message === 'You need to log in' ||
      graphQLErrors[0].message === 'Unauthorized'
    ) {
      window.localStorage.clear()
      return window.location.reload()
    }
    // @ts-ignore
    if (!ignoredErrors.includes(graphQLErrors[0].message)) {
      alert(graphQLErrors[0].message)
    }
  } else {
    alert(`Status code: 500 something went wrong`)
    console.log(graphQLErrors)
  }
})

let token: string

const authLink = setContext((_, { headers }) => {
  if (!token) {
    const loginData = localStorage.getItem('loginData')
    token = loginData ? JSON.parse(loginData).token : ''
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const api2link = createHttpLink({
  uri: `${process.env.REACT_APP_API_V2_URL}/graphql`,
})

const client = new ApolloClient({
  // @ts-ignore
  link: ApolloLink.from([errorLink, authLink, api2link]),
  typeDefs,
  resolvers,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          foods: {
            // Don't cache separate results based on
            // any of this field's arguments.
            keyArgs: false,

            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing = [], incoming) {
              return [...existing, ...incoming]
            },
          },
        },
      },
    },
  }),
})

const render = (Component: React.FC) =>
  ReactDOM.render(
    <ApolloProvider client={client}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#005471',
          },
        }}
      >
        <Router>
          <ConfirmProvider>
            <GlobalStyle />
            <Component />
          </ConfirmProvider>
        </Router>
      </ConfigProvider>
    </ApolloProvider>,
    document.getElementById('root'),
  )

render(App)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
