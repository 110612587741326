import React from 'react'
import styled from 'styled-components'
import MobileMenu from './MobileMenu/Main'
import DesktopMenu from './DesktopMenu/Main'
import MediaQuery from 'react-responsive'
import useLogin from '../../hooks/useLogin'
import { Link } from 'react-router-dom'
import { routes } from 'src/constants/routes'
import { media } from 'src/styles'
import VerifiedAccountCheck from './VerifiedAccountCheck'

const Main = () => {
  const { user } = useLogin()

  console.log('APP_ENV', process.env.REACT_APP_ENV)

  return (
    <>
      <Container>
        {process.env.REACT_APP_ENV === 'development' && <Alert>Testovací prostředí</Alert>}
        <MainHeadingLink to={routes.main}>
          <MainHeadingImg src="/img/logo.svg" alt="Na tácu - Databáze jídel" />
          <MainHeadingMobileImg src="/img/logo_small.svg" alt="Na tácu - Databáze jídel" />
        </MainHeadingLink>
        <MediaQuery minWidth={user && user.adminLevel >= 2 ? 2101 : 1430}>
          <DesktopMenu />
        </MediaQuery>
        <MediaQuery maxWidth={user && user.adminLevel >= 2 ? 2100 : 1429}>
          <MobileMenu />
        </MediaQuery>
      </Container>
      {user?.id && <VerifiedAccountCheck userId={user.id} />}
    </>
  )
}

export default Main

const Alert = styled.div`
  background: #da3636;
  text-align: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  color: white;
  font-size: 10px;
  font-weight: bold;
  padding: 2px;
  z-index: 10000;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  text-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);

  ${media.sm`
    padding: 10px 10%;
  `}
`

const MainHeadingImg = styled.img`
  height: 30px;
  padding: 16px 0;
  display: none;

  ${media.sm`
    display: block;
  `}
`
const MainHeadingMobileImg = styled.img`
  height: 30px;
  padding: 16px 0;
  display: block;

  ${media.sm`
    display: none;
  `}
`

const MainHeadingLink = styled(Link)`
  z-index: 1000;
  text-decoration: none;
  color: black;
`
