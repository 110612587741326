import { gql } from 'src/__generated__'

export default gql(`
  mutation login($email: String!, $password: String!) {
    login(data: {email: $email, password: $password}) {
      token
      user {
        id
        email
        userName
        adminLevel
        canteen {
          id
        }
      }
    }
  }
`)
