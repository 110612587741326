import { gql } from 'src/__generated__'
import { CommentsQueryQuery } from 'src/__generated__/graphql'

export type Comment = CommentsQueryQuery['comments'][0]

export default gql(`
  query commentsQuery($query: GetCommentsArgs!) {
    comments(query: $query) {
      id
      text
      date
      ratingTaste
      ratingVisual
      ratingPreparation
      food {
        id
        name
      }
      user {
        userName
      }
    }
  }
`)
