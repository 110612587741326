import { useLocalStorage } from '@rehooks/local-storage'
import { UserAdminLevel } from '../constants/constants'
import { LoginData } from '../constants/types'
import { LoginMutation } from 'src/__generated__/graphql'

const LOGIN_DATA_KEY = 'loginData'

export const saveLoginInfo = (login: LoginMutation['login']): void => {
  localStorage.setItem(
    LOGIN_DATA_KEY,
    JSON.stringify({
      token: login.token,
      user: {
        id: login.user.id,
        userName: login.user.userName,
        adminLevel: login.user.adminLevel,
        email: login.user.email,
        canteenId: login.user.canteen?.id,
      },
    }),
  )
}

const useLogin = (): LoginData => {
  const [loginDataString] = useLocalStorage(LOGIN_DATA_KEY)

  if (!loginDataString) return {}

  const loginData: LoginData =
    typeof loginDataString === 'string' ? JSON.parse(loginDataString) : loginDataString

  if (!loginData.user) return {}

  loginData.ADMIN = loginData.user.adminLevel === UserAdminLevel.ADMIN
  loginData.SUPERADMIN = loginData.user.adminLevel === UserAdminLevel.SUPERADMIN
  loginData.USER =
    loginData.user.adminLevel === UserAdminLevel.USER ||
    loginData.user.adminLevel === UserAdminLevel.INACTIVE_USER

  return loginData
}

export default useLogin
