import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/cs'
import locale from 'antd/locale/cs_CZ'

import canteenOfferHistory from 'src/queries/v2/canteenOfferHistory'
import LoaderSpinner from '../../layout/loader'
import OfferTable from '../canteen/OfferTable'
import { navy } from 'src/layout/colors'

const History = () => {
  const { id } = useParams<{ id: string }>()
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  const dateConstant = 604_800_000 // 7 days in ms

  const now = useMemo(() => new Date().getTime(), [])

  const [range, setRange] = useState({ from: now - dateConstant, to: now })

  const { loading, data } = useQuery(canteenOfferHistory, {
    variables: {
      query: {
        canteenId: parseInt(id!),
        from: range.from.toString(),
        to: range.to.toString(),
      },
    },
  })

  useEffect(() => {
    !loading &&
      data &&
      data.canteenOffers.length > 7 &&
      buttonRef.current?.scrollIntoView({ behavior: 'auto' })
  }, [loading])

  if (loading) {
    return <LoaderSpinner />
  }

  return (
    <>
      <Container100vh>
        <Container>
          <Inner>
            {/* <h1>Jídelna {data.canteen.currentCanteen.name}</h1>
            <p>{data.canteen.currentCanteen.region}</p> */}
            <PickerContainer>
              <Label>Vyberte datum</Label>
              <CustomRangePicker
                defaultValue={[dayjs(range.from), dayjs(range.to)]}
                // @ts-ignore
                locale={locale}
                onCalendarChange={(dates) => {
                  console.log(dates)
                  if (dates && dates[0] && dates[1])
                    setRange({ from: dates[0].toDate().getTime(), to: dates[1].toDate().getTime() })
                }}
              />
            </PickerContainer>
            {!data?.canteenOffers.length ? (
              <EmptyMessage>Bohužel jsme nic neneašli :(</EmptyMessage>
            ) : (
              <OfferTable
                canteenId={parseInt(id!)}
                offer={data?.canteenOffers?.filter((offer) => offer.food.length > 0)}
                hideExpandButton
                disableFilter
                lastOfferUpdateAt={null}
              />
            )}
          </Inner>
        </Container>
      </Container100vh>
    </>
  )
}

export default History

const EmptyMessage = styled.div``

const PickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`

const Label = styled.label`
  margin-top: 1rem;
  font-size: 1.125rem;
  font-weight: 700;
`

const CustomRangePicker = styled(DatePicker.RangePicker)`
  width: fit-content;
  padding: 0.625rem 1rem;
  margin-bottom: 2rem;
  border-radius: 5px;
  border: none;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
`

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  background: url('/img/pattern_modry.svg'), ${navy};
  background-attachment: fixed;
  background-position: center;
  background-size: 730px;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  height: 100%;
`

const Inner = styled.div`
  text-align: center;
  background-color: white;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  width: 80%;
  margin: 10px auto;
  border-radius: 5px;
  padding: 20px 20px;
`
