import React from 'react'
import styled from 'styled-components'
import { Close as CloseIcon, SearchOutlined as SearchOutlinedIcon } from '@mui/icons-material'

import { navy } from './colors'
import TextInput from './textInput'

interface Props {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  clearSearch: () => void
  placeholder?: string
}

const Search = ({ value, onChange, clearSearch, placeholder }: Props) => {
  return (
    <Container>
      <SearchIcon />
      <SearchBar placeholder={placeholder} type="text" value={value} onChange={onChange} />
      <Close onClick={clearSearch} />
    </Container>
  )
}

export default Search

const Container = styled.div`
  text-align: left;
  position: relative;
  width: 100%;
  max-width: 420px;
  margin: 0 10px;

  @media (min-width: 600px) {
    width: 500px;
    max-width: 500px;
    margin: 0;
  }
`

const SearchBar = styled(TextInput)`
  box-sizing: border-box;
  width: 100%;
  outline-color: ${navy};
  padding-left: 40px;
  padding-right: 40px;
  margin: 0;
`

const SearchIcon = styled(SearchOutlinedIcon)`
  position: absolute;
  top: 14px;
  left: 11px;
  color: ${navy};
`

const Close = styled(CloseIcon)`
  position: absolute;
  top: 14px;
  right: 11px;
  color: ${navy};
  cursor: pointer;
`
