import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useMutation } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router'
import Select from 'react-select'

import { backgroundGray, navy } from '../../layout/colors'
import TextInput from '../../layout/textInput'
import LoaderSpinner from '../../layout/loader'
import { InputValueStateType } from '../../constants/types'
import canteens from '../../queries/v2/canteens'
import editCanteen from '../../mutations/v2/editCanteen'
import canteen from 'src/queries/v2/canteen'
import { customStyles } from '../editUser/Main'
import { CanteenSystemType, UpdateCanteenInput } from 'src/__generated__/graphql'
import { routes } from 'src/constants/routes'
import Button from 'src/components/Button'

interface Props {
  canteenId: number
}

const Main = ({ canteenId }: Props) => {
  const navigate = useNavigate()

  const [submitButtonInactive, setSubmitButtonInactive] = useState(true)
  const [error, setError] = useState(false)

  const [formState, setFormState] = useState<
    InputValueStateType<
      'name' | 'region' | 'city' | 'slug' | 'canteenSystemType' | 'externalSystemId'
    >
  >({
    name: { value: '', isInvalid: false },
    region: { value: '', isInvalid: false },
    city: { value: '', isInvalid: false },
    slug: { value: '', isInvalid: false },
    canteenSystemType: { value: '', isInvalid: false },
    externalSystemId: { value: '', isInvalid: false },
  })
  const refetchQueries = {
    refetchQueries: [
      {
        query: canteens,
      },
      {
        query: canteen,
        variables: { id: canteenId },
      },
    ],
  }
  const [reg] = useMutation(editCanteen, {
    ...refetchQueries,
  })

  async function handleSubmit() {
    if (formState.name.isInvalid || formState.region.isInvalid || formState.city.isInvalid) {
      return setError(true)
    }
    const data: UpdateCanteenInput = {
      name: formState.name.value,
      region: formState.region.value,
      city: formState.city.value,
      slug: formState.slug.value,
      canteenSystemType: (formState.canteenSystemType.value as CanteenSystemType) || undefined,
      externalSystemId: formState.externalSystemId.value,
      id: canteenId,
    }
    setError(false)
    await reg({ variables: { data } })
    navigate(routes.canteens)
    return
  }

  const { data, loading } = useQuery(canteen, {
    variables: { id: canteenId },
  })

  useEffect(() => {
    setFormState({
      name: {
        value: data?.canteen.name ?? '',
        isInvalid: false,
      },
      region: {
        value: data?.canteen.region ?? '',
        isInvalid: false,
      },
      city: {
        value: data?.canteen.city ?? '',
        isInvalid: false,
      },
      slug: {
        value: data?.canteen.slug ?? '',
        isInvalid: false,
      },
      canteenSystemType: {
        value: data?.canteen.canteenSystemType ?? '',
        isInvalid: false,
      },
      externalSystemId: {
        value: data?.canteen.externalSystemId ?? '',
        isInvalid: false,
      },
    })
    setSubmitButtonInactive(true)
  }, [data])

  useEffect(() => {
    if (formState.name.isInvalid || formState.region.isInvalid) setSubmitButtonInactive(true)
    else setSubmitButtonInactive(false)
  }, [formState])

  function handleValidation(value: string) {
    return false
    // return !/^[\sa-zá-žA-ZÁ-Ž0-9._-]+$/.test(value)
  }

  if (loading) {
    return <LoaderSpinner />
  }

  const canteenSystemsOptions = [
    { value: CanteenSystemType.Strava, label: 'strava.cz' },
    { value: CanteenSystemType.Ejidelnicek, label: 'e-jidelnicek.cz' },
    { value: CanteenSystemType.Estrava, label: 'e-Strava.cz (Ulrich SW)' },
  ]

  return (
    <>
      <Container100vh>
        <Container>
          <LoginForm>
            <Heading>
              Úprava jídelny
              <br />
              <br />
              {data?.canteen.name}
            </Heading>
            <TextInput
              type="text"
              placeholder="Název jídelny"
              name="text"
              value={formState.name.value}
              isInvalid={formState.name.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  name: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value),
                  },
                })
              }
            />
            <TextInput
              type="text"
              placeholder="Kraj"
              value={formState.region.value}
              isInvalid={formState.region.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  region: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value),
                  },
                })
              }
            />
            <TextInput
              type="text"
              placeholder="Město"
              value={formState.city.value}
              isInvalid={formState.city.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  city: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value),
                  },
                })
              }
            />
            <TextInput
              type="text"
              placeholder="URL slug (bez lomítka)"
              value={formState.slug.value}
              isInvalid={formState.slug.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  slug: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value),
                  },
                })
              }
            />
            <p>Systém:</p>
            <Select
              styles={customStyles}
              options={canteenSystemsOptions}
              value={canteenSystemsOptions.find(
                (obj) => obj.value === formState.canteenSystemType.value,
              )}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  canteenSystemType: {
                    value: e ? e.value : '',
                    isInvalid: false,
                  },
                })
              }
              placeholder={'Vyberte typ systému'}
            />
            <TextInput
              type="text"
              placeholder="ID externího systému"
              value={formState.externalSystemId.value}
              isInvalid={formState.externalSystemId.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  externalSystemId: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value),
                  },
                })
              }
            />
            <div>{error && <LoginError>Hesla se neshodují</LoginError>}</div>
            <br />
            <Button disabled={submitButtonInactive} onClick={handleSubmit}>
              Provést změny
            </Button>
          </LoginForm>
        </Container>
      </Container100vh>
    </>
  )
}

export default Main

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;

  background: url('/img/pattern_modry.svg'), ${navy};
  background-attachment: fixed;
  background-position: center;
  background-size: 730px;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  padding: 30px 0;
  height: 100%;
`

const LoginForm = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 50px 20px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  background-color: white;

  @media (min-width: 500px) {
    padding: 20px 50px 50px 50px;
    width: 70%;
  }

  @media (min-width: 600px) {
    width: 50%;
  }

  @media (min-width: 900px) {
    width: 30%;
  }

  @media (min-width: 2000px) {
    width: 20%;
  }

  @media (min-width: 3000px) {
    width: 15%;
  }
`
const Heading = styled.h2`
  text-align: center;
`

const LoginError = styled.span`
  color: #fc6969;
  font-weight: 500;
  font-size: 0.81em;
  position: absolute;
  margin-bottom: 0.75em;
  margin-top: -0.8em;
  display: flex;
  justify-content: flex-start;
`
