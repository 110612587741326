import React from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'

import LoaderSpinner from '../../layout/loader'
import SingleDuplicate from './SingleDuplicate'
import { backgroundGray, navy } from '../../layout/colors'
import foodsConflicts from '../../queries/v2/foodsConflicts'
import { media } from 'src/styles'

type Props = {
  canteenId: number
}

const Main: React.FC<Props> = ({ canteenId }) => {
  const { loading, data, refetch } = useQuery(foodsConflicts, {
    variables: { canteenId },
  })

  if (loading) return <LoaderSpinner />

  return (
    <>
      <Container100vh>
        <Container>
          <Inner>
            <h1>Duplicitní pokrmy</h1>

            <p>
              Slučte jídla, které jsou stejná, do jednoho. Pokud se správně jedná o dvě různá jídla,
              tak sloučení odmítněte.
            </p>
            {data?.foodsConflicts.length === 0 && 'Žádné konflikty nebyly nalezeny.'}
            {data?.foodsConflicts.map((item) => {
              return (
                <SingleDuplicate
                  foodA={item[0]}
                  foodB={item[1]}
                  key={`${item[0]?.id}_${item[1]?.id}`}
                  refetchQueries={() => {
                    refetch({
                      canteenId: canteenId,
                    })
                  }}
                />
              )
            })}
          </Inner>
        </Container>
      </Container100vh>
    </>
  )
}

export default Main

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  background: url('/img/pattern_modry.svg'), ${navy};
  background-attachment: fixed;
  background-position: center;
  background-size: 730px;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  height: 100%;
`

const Inner = styled.div`
  text-align: center;
  background-color: white;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  width: 80%;
  margin: 10px auto;
  border-radius: 5px;
  padding: 20px 20px;

  ${media.maxSm`
    padding: 10px;
    width: 90%;
  `}
`
