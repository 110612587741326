import React from 'react'
import { Navigate } from 'react-router-dom'
import useLogin from '../hooks/useLogin'
import { routes } from 'src/constants/routes'
import Props from './Props'

const LoginRoute = ({ children }: Props) => {
  const loginData = useLogin()

  return loginData.user ? <Navigate to={routes.main} /> : children
}

export default LoginRoute
