const routes = {
  index: '/',
  main: '/jidelny',
  about: '/o-nas',
  howItWorks: '/jak-to-funguje',
  foodWaste: '/pribeh-jidla',
  canteenDetail: (id: string | number) => `/jidelna/${id}`,
  canteenDetailNiceUrl: (slug: string) => `/${slug}`,
  canteenDetailHistory: (id: string | number) => `/jidelna/${id}/nabidka`,
  foodDetail: (id: string | number) => `/pokrmy/${id}`,

  // legal
  privacyDataPolicy: '/pdf/zpracovani_osobnich_udaju.pdf',

  // user
  login: '/prihlaseni',
  register: '/registrace',
  forgotPassword: '/zapomenute-heslo',
  renewPassword: '/obnovit-heslo/:userId/:token',
  verifyAccount: '/overit-ucet/:userId/:token',
  editUser: '/profil/upravit',

  // food admin
  foodDetailEdit: (id: string | number) => `/admin/pokrmy/${id}/upravit`,
  foodDetailAddPhoto: (id: string | number) => `/admin/pokrmy/${id}/pridat-foto`,

  // canteen admin
  canteens: '/admin/jidelna',
  canteenAdd: '/admin/jidelna/pridat',
  canteenEdit: (id: string | number) => `/admin/jidelna/${id}/upravit`,
  canteenDetailFood: (id: string | number) => `/admin/jidelna/${id}/pokrmy`,
  canteenDetailFoodDuplicates: (id: string | number) => `/admin/jidelna/${id}/pokrmy/duplicity`, // unused
  canteenDetailFoodAdd: (id: string | number) => `/admin/jidelna/${id}/pokrmy/pridat`, // unused
  canteenDetailOfferAdd: (id: string | number) => `/admin/jidelna/${id}/nabidka/pridat`, // unused
  canteenDetailComments: (id: string | number) => `/admin/jidelna/${id}/hodnoceni`,

  // superadmin
  users: '/admin/uzivatele',
  usersDetailEdit: (id: string | number) => `/admin/uzivatele/${id}/upravit`,

  photos: '/photos',
}

export { routes }
