import { useState } from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'

import { customStyles } from '../editUser/Main'
import Button from 'src/components/Button'
import { navy } from 'src/layout/colors'

const MenuMakerForm = () => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()

  const [date, setDate] = useState<{
    year: string | undefined
    month: string | undefined
  }>({
    year: '2023',
    month: '06',
  })

  const now = new Date()

  return (
    <Container100vh>
      <Container>
        <Inner>
          <InputContainer>
            <Select
              isSearchable={true}
              styles={{
                ...customStyles,
                option: (provided: any, state: any) => ({
                  ...provided,
                  backgroundColor: state.isFocused ? '#ee982b' : '#F5F5F5',
                  color: state.isFocused ? '#F5F5F5' : '#ee982b',
                }),
              }}
              options={[...Array(12)].map((_, month) => ({
                value: month + 1,
                label: month + 1,
              }))}
              //isInvalid={formState.rights.isInvalid}
              onChange={(e) => setDate((date) => ({ ...date, month: e!.value.toString() }))}
              placeholder={'Vyberte měsíc'}
              noOptionsMessage={() => 'Žádný měsíc nenalezen'}
              value={{
                value: now.getMonth() + 1,
                label: now.getMonth() + 1,
              }}
            />

            <Select
              isSearchable={true}
              styles={{
                ...customStyles,
                option: (provided: any, state: any) => ({
                  ...provided,
                  backgroundColor: state.isFocused ? '#ee982b' : '#F5F5F5',
                  color: state.isFocused ? '#F5F5F5' : '#ee982b',
                }),
              }}
              options={[...Array(4)].map((_, index) => ({
                value: 2022 + index,
                label: 2022 + index,
              }))}
              //isInvalid={formState.rights.isInvalid}
              onChange={(e) => setDate((date) => ({ ...date, year: e!.value.toString() }))}
              placeholder={'Vyberte rok'}
              value={{
                value: now.getFullYear(),
                label: now.getFullYear(),
              }}
              noOptionsMessage={() => 'Žádný rok nenalezen'}
            />
          </InputContainer>
          <Button
            onClick={() => navigate(`/menuMaker/${id}/${date?.year || 0}-${date?.month || 0}`)}
          >
            Vygenerovat
          </Button>
        </Inner>
      </Container>
    </Container100vh>
  )
}

export default MenuMakerForm

const InputContainer = styled.div`
  display: flex;
  gap: 2rem;
  margin: 0 auto;
  width: fit-content;
  align-items: center;
`

const StyledSelect = styled.input``

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  background: url('/img/pattern_modry.svg'), ${navy};
  background-attachment: fixed;
  background-position: center;
  background-size: 730px;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  height: 100%;
`

const Inner = styled.div`
  text-align: center;
  background-color: white;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  width: 80%;
  margin: 10px auto;
  border-radius: 5px;
  padding: 20px 20px;
`
