import { navy } from 'src/layout/colors'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
    .MuiDialog-container {
        .MuiTypography-h6 {
            line-height: 1.2 !important;
            font-weight: bold !important;
            font-size: 1.1rem !important;
        }
        .MuiButton-textPrimary {
            background: ${navy} !important;
            color: white !important;
        }
    }

`

export { GlobalStyle }
