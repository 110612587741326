import { gql } from 'src/__generated__'
import { UsersQueryQuery } from 'src/__generated__/graphql'

export type User = UsersQueryQuery['users'][0]

export default gql(`
  query usersQuery {
    users {
      id
      email
      isEmailVerified
      adminLevel
      userName
      canteen {
        id
        name
      }
    }
  }
`)
