import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import { useParams, useNavigate } from 'react-router'

import { routes } from 'src/constants/routes'
import { ButtonLink } from 'src/components/Button'
import verifyAccountConfirm from 'src/mutations/v2/verifyAccountConfirm'
import user from 'src/queries/v2/user'
import LoaderSpinner from 'src/layout/loader'
import { navy } from 'src/layout/colors'

const Main = () => {
  const navigate = useNavigate()
  const ref = useRef<HTMLButtonElement>(null)

  const params = useParams<{ userId: string; token: string }>()
  const userId = parseInt(params.userId!)

  const [reg, result] = useMutation(verifyAccountConfirm, {
    variables: {
      data: {
        userId: userId,
        token: params.token!,
      },
    },
    refetchQueries: [
      {
        query: user,
        variables: { userId },
      },
    ],
    onCompleted() {
      // navigate(routes.login)
    },
  })

  useEffect(() => {
    const sendRequest = async () => {
      try {
        await reg()
      } catch (error) {
        console.log(error)
      }
    }
    sendRequest()
  }, [])

  const isSuccess = !!result.data?.verifyAccountConfirm

  return (
    <>
      <Container100vh>
        <Container>
          <LoginForm>
            {result.loading && (
              <>
                <Heading>Oveření účtu</Heading>
                <p>
                  <LoaderSpinner />
                </p>
              </>
            )}
            {result.error && (
              <>
                <Heading>Oveření účtu se nepovedlo</Heading>
                <p>Zkuste to prosím později</p>
              </>
            )}
            {isSuccess && (
              <>
                <Heading>Oveření účtu bylo úspěšné</Heading>
                <p>Nyní můžete hodnotit pokrmy své školní jídelny.</p>
                <ButtonLink to={routes.login}>Pokračovat</ButtonLink>
              </>
            )}
          </LoginForm>
        </Container>
      </Container100vh>
    </>
  )
}

export default Main

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;

  background: url('/img/pattern_modry.svg'), ${navy};
  background-attachment: fixed;
  background-position: center;
  background-size: 730px;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  padding: 30px 0;
  height: 100%;
`

const LoginForm = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 50px 20px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  background-color: white;
  text-align: center;

  @media (min-width: 500px) {
    padding: 20px 50px 50px 50px;
    width: 70%;
  }

  @media (min-width: 600px) {
    width: 50%;
  }

  @media (min-width: 900px) {
    width: 30%;
  }

  @media (min-width: 2000px) {
    width: 20%;
  }

  @media (min-width: 3000px) {
    width: 15%;
  }
`

const Heading = styled.h2`
  text-align: center;
`
