import { gql } from 'src/__generated__'
import { PhotosQueryQuery } from 'src/__generated__/graphql'

export type Photo = PhotosQueryQuery['photos'][0]

export default gql(`
  query photosQuery($foodId: Int,  $approved: Boolean) {
    photos(query: {foodId: $foodId, approved: $approved}) {
      id
      s3url
      food {
        id
        name
      }
    }
  }
`)
