import React from 'react'
import { useMutation } from '@apollo/client'
import { useConfirm } from 'material-ui-confirm'
import { Link } from 'react-router-dom'

import { FoodInConflict } from 'src/queries/v2/foodsConflicts'
import rejectFoodConflict from 'src/mutations/v2/rejectFoodConflict'
import Button from 'src/components/Button'
import { routes } from 'src/constants/routes'

import * as S from './styled'
import MergeModal from '../MergeModal'

type Props = {
  foodA: FoodInConflict
  foodB: FoodInConflict
  refetchQueries?: () => void
}

const SingleDuplicate: React.FC<Props> = ({ foodA, foodB, refetchQueries }) => {
  const [isHidden, setIsHidden] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const confirm = useConfirm()
  const [req] = useMutation(rejectFoodConflict, {
    onCompleted: () => {
      setIsHidden(true)
      refetchQueries?.()
    },
  })

  function handleAccept() {
    setOpen(true)
  }

  function handleDecline() {
    confirm({
      title: `Odmítnout sloučení "${foodA.name}" a "${foodB.name}"?`,
      description: `Tato akce je nevratná.`,
      cancellationText: 'Zrušit',
    }).then(() => {
      req({
        variables: {
          data: {
            foodAId: foodA.id,
            foodBId: foodB.id,
          },
        },
      })
    })
  }

  const renderFood = (food: FoodInConflict) => {
    return (
      <Link to={routes.foodDetail(food.id)}>
        <S.FoodItemContainer>
          <S.ImageContainer>
            <S.Image src={food.photos?.[0]?.s3url ?? '/img/placeholder.png'} loading="lazy" />
          </S.ImageContainer>
          <p>
            {food.name}
            <br />
            <span>ID: {food.id}</span>
          </p>
        </S.FoodItemContainer>
      </Link>
    )
  }

  if (isHidden) {
    return null
  }

  return (
    <S.Wrap>
      {renderFood(foodA)}
      <S.Buttons>
        <h3>Vyřešit konflikty</h3>
        <S.ButtonsWrap>
          <Button onClick={handleAccept}>
            <span>Sloučit</span>
          </Button>
          <Button variant="outlined" onClick={handleDecline}>
            <span>Odmítnout</span>
          </Button>
        </S.ButtonsWrap>
      </S.Buttons>
      {renderFood(foodB)}
      <MergeModal
        foodA={foodA}
        foodB={foodB}
        open={open}
        onClose={() => setOpen(false)}
        onCompleted={() => {
          setIsHidden(true)
          window.open(routes.foodDetail(foodA.id), '_blank')
          setTimeout(() => {
            refetchQueries?.()
          }, 1_000)
        }}
      />
    </S.Wrap>
  )
}

export default SingleDuplicate
