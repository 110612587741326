import React from 'react'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'

import canteen from 'src/queries/v2/canteen'
import LoaderSpinner from '../../layout/loader'
import OfferTable from './OfferTable'
import OfferSection from './OfferSection'
import useLogin from '../../hooks/useLogin'
import { useCanteenOfferForNextMonth } from './useCanteenOfferForNextMonth'
import { UserAdminLevel } from 'src/constants/constants'
import { CanteenSystemType } from 'src/__generated__/graphql'
import { routes } from 'src/constants/routes'
import { ButtonA, ButtonLink } from 'src/components/Button'

import * as S from './styled'

const Canteen = () => {
  const { id } = useParams<{ id: string }>()
  const { user } = useLogin()

  const { loading, data } = useQuery(canteen, {
    variables: { id: parseInt(id!) },
  })
  const {
    loading: loadingOffer,
    data: dataOfferNextMonth,
    refetch,
  } = useCanteenOfferForNextMonth({
    canteenId: id!,
  })

  if (loading || loadingOffer || !data) {
    return <LoaderSpinner />
  }

  const isAdmin = user && user.adminLevel === UserAdminLevel.SUPERADMIN

  return (
    <S.Container100vh>
      <S.Container>
        <S.Inner>
          <h1>Jídelna {data.canteen.name}</h1>
          <p>{data.canteen.region}</p>
          <S.ButtonsWrap>
            {data.canteen.canteenSystemType === CanteenSystemType.Strava ? (
              <ButtonA
                href={`https://www.strava.cz/Strava/Stravnik/Jidelnicky?zarizeni=${data.canteen.externalSystemId}`}
                target={'_blank'}
              >
                strava.cz
              </ButtonA>
            ) : data.canteen.canteenSystemType === CanteenSystemType.Ejidelnicek ? (
              <ButtonA
                href={`https://e-jidelnicek.cz/menu/?canteenNumber=${data.canteen.externalSystemId}`}
                target={'_blank'}
              >
                e-jidelnicek.cz
              </ButtonA>
            ) : data.canteen.canteenSystemType === CanteenSystemType.Estrava ? (
              <ButtonA
                href={`https://secure.ulrichsw.cz/estrava/stara/jidelnicek2.php?idzar=${data.canteen.externalSystemId}&lang=CZ`}
                target={'_blank'}
              >
                e-Strava.cz
              </ButtonA>
            ) : null}
            <ButtonA href="#search">Vyhledávání</ButtonA>
            <ButtonLink to={routes.canteenDetailHistory(data.canteen.id)}>Historie</ButtonLink>
          </S.ButtonsWrap>

          {isAdmin && (
            <S.ButtonsWrap>
              <ButtonLink to={routes.canteenDetailFood(data.canteen.id)} variant="outlined">
                Administrace pokrmů
              </ButtonLink>
              <ButtonLink to={routes.canteenDetailComments(data.canteen.id)} variant="outlined">
                Hodnocení
              </ButtonLink>
            </S.ButtonsWrap>
          )}
          {dataOfferNextMonth && (
            <OfferTable
              offer={dataOfferNextMonth?.canteenOffers?.filter((offer) => offer.food.length > 0)}
              canteenId={data.canteen.id}
              refetchQueries={refetch}
              lastOfferUpdateAt={
                data.canteen.lastOfferUpdateAt ? new Date(data.canteen.lastOfferUpdateAt) : null
              }
            />
          )}

          <S.OfferHeadline id="search">Nabídka pokrmů</S.OfferHeadline>
          <OfferSection />
        </S.Inner>
      </S.Container>
    </S.Container100vh>
  )
}

export default Canteen
