import { gql } from 'src/__generated__'

export default gql(`
  query generateNewFoodQuery($tags: [String!]!) {
    generateNewFood(tags: $tags) {
      ingredients
      name
      recipe
    }
  }
`)
