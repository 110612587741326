import React from 'react'
import { navy } from 'src/layout/colors'
import styled from 'styled-components'

const HowItWorks = () => {
  return (
    <Container100vh>
      <Content>
        <Heading>Jak to funguje?</Heading>
        <Text>
          Určitě se ti někdy stalo, že ses podíval do jídelníčku a nevěděl, co název jídla, jako
          třeba maso pěti barev, znamená, a jestli ho tím pádem chceš, anebo si radši objednáš něco
          jiného. Skvělé kuchařky z každé zapojené jídelny denně fotí a neustále aktualizují
          databázi jídel jejich jídelny, přičemž ke každému je přiřazená fotka, takže si jednoduše
          na hlavní stránce našeho webu vyhledáš jídlo, které tě zajímá a uvidíš jeho nafocené
          varianty, které někdy už v jídelně byly. Jídlo po zaregistrování můžeš taky ohodnotit a
          okomentovat, přičemž tak dáš zpětnou vazbu kuchařkám, které budou vědět jak jednotlivá
          jídla vylepšit, a zároveň spolužákům, aby věděli, jak si jídlo vede v porovnání s
          ostatními.
        </Text>
        <Text>
          <b>Příklad:</b> Nevím co si mám příští úterý vybrat za jídlo, smetanové kuřecí nudličky se
          špenátem, sladký kuskus s ovocem nebo pečené kuřecí stehno s míchanými luštěninami? Na
          hlavní stránce kliknu na vyhledávání a zadám “kuřecí” nebo třeba “nudličky” a podívám se
          jaká jídla v databázi jsou, zjistím, že v ní je jídlo s názvem smetanové kuřecí se
          špenátem - dojde mi, že to bude nejspíš to jídlo které hledám nebo jemu hodně podobné, a
          proto ho rozkliknu, podívám se na fotku, hodnocení a případné komentáře. Podobně to udělám
          s kuskusem i kuřecím stehnem a vytvořím si na základě toho obrázek, co by mi asi mohlo
          nejvíce chutnat.
        </Text>
      </Content>
    </Container100vh>
  )
}

export default HowItWorks

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  background: url('/img/pattern_modry.svg'), ${navy};
  background-attachment: fixed;
  background-position: center;
  background-size: 730px;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Content = styled.div`
  text-align: center;
  background-color: white;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  width: 80%;
  margin: 10px auto;
  border-radius: 5px;
  padding: 20px 5rem;
  max-width: 60rem;

  @media (max-width: 900px) {
    padding: 20px 2rem;
  }
`

const Heading = styled.h1`
  text-align: center;
`

const Text = styled.p`
  text-align: justify;
`
