import { gql } from 'src/__generated__'
import { UserQueryQuery } from 'src/__generated__/graphql'

export type User = UserQueryQuery['user']

export default gql(`
  query userQuery($userId: Int!) {
    user(id: $userId) {
      id
      userName
      email
      isEmailVerified
      adminLevel
      canteen {
        id
      }
    }
  }
`)
