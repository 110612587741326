import styled, { css } from 'styled-components'
import { backgroundGray } from './colors'

export interface TextInputProps {
  isInvalid?: boolean
}

const TextInput = styled.input<TextInputProps>`
  font-family: inherit;
  margin: 20px 0;
  border: 2px solid transparent;
  outline: none;
  padding: 15px;
  background-color: ${backgroundGray};
  border-radius: 5px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      border: 2px solid red;
    `}
`

const TextArea = styled.textarea<TextInputProps>`
  width: 100%;
  max-width: 700px;
  box-sizing: border-box;
  resize: vertical;
  font-family: inherit;
  border: 2px solid transparent;
  outline: none;
  padding: 15px;
  background-color: ${backgroundGray};
  border-radius: 5px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  height: 7rem;

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      border: 2px solid red;
    `}
`

export { TextArea }
export default TextInput
