import React, { useState } from 'react'
import styled from 'styled-components'
import { Close as CloseIcon, Menu as MenuIcon } from '@mui/icons-material'

import { navy } from 'src/layout/colors'
import HamburgerMenu from './HamburgerMenu'

const MobileMenu = () => {
  const [open, setOpen] = useState(false)
  return (
    <Container>
      {open ? <Close onClick={() => setOpen(false)} /> : <OpenIcon onClick={() => setOpen(true)} />}
      <HamburgerMenu setOpen={setOpen} open={open} />
    </Container>
  )
}
export default MobileMenu

interface OpenIconProps {
  onClick: () => void
}

const Container = styled.div`
  display: flex;
  overflow-x: unset;
`

const OpenIcon = styled(MenuIcon)<OpenIconProps>`
  cursor: pointer;
  z-index: 1000;
  color: ${navy};
`

interface CloseProps {
  onClick: () => void
}

const Close = styled(CloseIcon)<CloseProps>`
  cursor: pointer;
  z-index: 1000;
  color: ${navy};
`
