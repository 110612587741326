import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { useParams, useNavigate } from 'react-router'

import TextInput, { TextArea } from '../../layout/textInput'
import LoaderSpinner from '../../layout/loader'
import { InputValueStateType } from '../../constants/types'
import foods from '../../queries/v2/foods'
import food from '../../queries/v2/food'
import editFood from '../../mutations/v2/editFood'
import { routes } from 'src/constants/routes'
import { UpdateFoodInput } from 'src/__generated__/graphql'
import Button from 'src/components/Button'
import { navy } from 'src/layout/colors'

interface Props {
  userId: number
}

const Main = ({ userId }: Props) => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()

  if (id) {
    userId = parseInt(id)
  }

  const [submitButtonInactive, setSubmitButtonInactive] = useState(true)
  const [error, setError] = useState(false)

  const [formState, setFormState] = useState<InputValueStateType<'name' | 'description'>>({
    name: { value: '', isInvalid: false },
    description: { value: '', isInvalid: false },
  })
  const refetchQueries = {
    refetchQueries: [
      {
        query: foods,
        variables: { canteenId: parseInt(id!) },
      },
    ],
  }
  const [reg] = useMutation(editFood, {
    ...refetchQueries,
  })

  function handleSubmit() {
    if (formState.name.isInvalid) {
      return setError(true)
    }
    const data: UpdateFoodInput = {
      name: formState.name.value,
      description: formState.description.value,
      id: parseInt(id!),
    }
    setError(false)
    navigate(routes.foodDetail(id!))
    return reg({ variables: { data } })
  }

  const getActualFood = useQuery(food, {
    variables: { id: parseInt(id!) },
  })

  useEffect(() => {
    setFormState({
      name: {
        value: getActualFood.loading ? '' : getActualFood?.data?.food.name ?? '',
        isInvalid: false,
      },
      description: {
        value: getActualFood.loading ? '' : getActualFood?.data?.food.description ?? '',
        isInvalid: false,
      },
    })
    setSubmitButtonInactive(true)
  }, [getActualFood.data])

  useEffect(() => {
    if (formState.name.isInvalid) setSubmitButtonInactive(true)
    else setSubmitButtonInactive(false)
  }, [formState])

  function handleValidation(value: string) {
    return false
  }

  if (getActualFood.loading) {
    return <LoaderSpinner />
  }

  return (
    <Container100vh>
      <Container>
        <LoginForm>
          <Heading>
            Úprava jídla
            <br />
            <br />
            {getActualFood?.data?.food.name}
          </Heading>
          <TextInput
            type="text"
            placeholder="Název jídla"
            name="text"
            value={formState.name.value}
            isInvalid={formState.name.isInvalid}
            disabled={true}
            onChange={(e) =>
              setFormState({
                ...formState,
                name: {
                  value: e.target.value,
                  isInvalid: handleValidation(e.target.value),
                },
              })
            }
          />
          <TextArea
            placeholder="Popis jídla"
            value={formState.description.value}
            isInvalid={formState.description.isInvalid}
            onChange={(e) =>
              setFormState({
                ...formState,
                description: {
                  value: e.target.value,
                  isInvalid: handleValidation(e.target.value),
                },
              })
            }
          />
          <div>{error && <LoginError>Chyba</LoginError>}</div>
          <br />
          <Button disabled={submitButtonInactive} onClick={handleSubmit}>
            Provést změny
          </Button>
          <AlternateNames>
            <p>
              <strong>Alternativní názvy jídla:</strong>
              <br />
              {getActualFood.data?.food.similarNames?.map((item) => {
                return (
                  <span key={item.alternateName}>
                    {item.alternateName}
                    <br />
                  </span>
                )
              })}
              {getActualFood.data?.food.similarNames?.length === 0 &&
                'Toto jídlo nemá žádné alternativní názvy'}
            </p>
          </AlternateNames>
        </LoginForm>
      </Container>
    </Container100vh>
  )
}

export default Main

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;

  background: url('/img/pattern_modry.svg'), ${navy};
  background-attachment: fixed;
  background-position: center;
  background-size: 730px;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  padding: 30px 0;
  height: 100%;
`

const LoginForm = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 50px 20px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  background-color: white;

  @media (min-width: 500px) {
    padding: 20px 50px 50px 50px;
    width: 70%;
  }

  @media (min-width: 600px) {
    width: 50%;
  }

  @media (min-width: 900px) {
    width: 30%;
  }

  @media (min-width: 2000px) {
    width: 20%;
  }

  @media (min-width: 3000px) {
    width: 15%;
  }
`

const Heading = styled.h2`
  text-align: center;
`

const LoginError = styled.span`
  color: #fc6969;
  font-weight: 500;
  font-size: 0.81em;
  position: absolute;
  margin-bottom: 0.75em;
  margin-top: -0.8em;
  display: flex;
  justify-content: flex-start;
`

const AlternateNames = styled.div`
  opacity: 0.7;
  font-size: 14px;
  margin-top: 32px;
`
