import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import user from 'src/queries/v2/user'
import verifyAccountRequest from 'src/mutations/v2/verifyAccountRequest'

import * as S from './styled'
import useLogin from 'src/hooks/useLogin'
import { UserAdminLevel } from 'src/constants/constants'

type Props = {
  userId: number
}

const VerifiedAccountCheck: React.FC<Props> = ({ userId }) => {
  const { user: currentUser } = useLogin()
  const [isSent, setIsSent] = useState(false)

  const userData = useQuery(user, {
    variables: { userId },
  })

  const [sendVerifyEmail] = useMutation(verifyAccountRequest)

  const resendEmail = () => {
    return sendVerifyEmail({
      variables: {
        data: {
          userId,
        },
      },
      onCompleted: () => {
        setIsSent(true)
      },
    })
  }

  if (currentUser?.adminLevel !== UserAdminLevel.USER) {
    return null
  }

  const isUserVerified = userData?.data?.user?.isEmailVerified

  return isUserVerified ? null : (
    <S.Wrap>
      <p>
        Pro hodnocení jídel je třeba dokončit ověření účtu. Klikněte na odkaz, který jsme poslali na
        váš email {userData.data?.user.email}.
        {!isSent ? (
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault()
              resendEmail()
            }}
          >
            Odeslat znovu
          </a>
        ) : (
          <>
            <br />
            <br />
            <span>Ověřovací email byl odeslán.</span>
          </>
        )}
      </p>
    </S.Wrap>
  )
}

export default VerifiedAccountCheck
