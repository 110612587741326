import { gql } from 'src/__generated__'
import { FoodQueryQuery } from 'src/__generated__/graphql'

export type Food = FoodQueryQuery['food']

export default gql(`
  query foodQuery($id: Int!) {
    food(id: $id) {
      id
      name
      description
      canteenId
      averageRating
      similarNames {
        alternateName
      }
      photos {
        id
        s3url
      }
    }
  }
`)
