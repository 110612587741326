import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router'

import LoaderSpinner from '../../layout/loader'
import foods from '../../queries/v2/foods'
import Search from '../../layout/Search'
import { ExpandButton } from '../menuMaker/OfferTable'
import { routes } from 'src/constants/routes'

const PAGE_SIZE = 12

const OfferSection = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const [searchValue, setSearchValue] = useState('')
  const [loaded, setLoaded] = useState<number[]>([])
  const abortController = new AbortController()

  const { loading, data, fetchMore, refetch } = useQuery(foods, {
    variables: {
      canteenId: parseInt(id!),
      offset: 0,
      limit: PAGE_SIZE,
      search: searchValue,
    },
  })

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchValue.length >= 3 || searchValue === '') {
        refetch({
          canteenId: parseInt(id!),
          offset: 0,
          limit: PAGE_SIZE,
          search: searchValue,
        })
      }
    }, 500)

    return () => clearTimeout(timeout)
  }, [searchValue])

  // if (loading && !data) {
  //   return <LoaderSpinner />
  // }

  console.log(data)

  return (
    <>
      <SearchContainer>
        <SearchFood
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          clearSearch={() => setSearchValue('')}
          placeholder={`např.: ${data?.foods?.[0] ? data.foods[0].name : 'Guláš'}`}
        />
      </SearchContainer>

      <Container>
        {data?.foods.length === 0 && searchValue && <p>Žádné jídlo se nepodařilo najít</p>}

        {data?.foods.map((food, index) => {
          const img = food.photos?.[0]
          return (
            <FoodItemContainer onClick={() => navigate(routes.foodDetail(food.id))} key={food.id}>
              <ImageContainer>
                {!loaded.includes(index) && (
                  <LoaderContainer>
                    <LoaderSpinner notAbsolute={true} />
                  </LoaderContainer>
                )}
                <Image
                  src={img?.s3url ?? '/img/placeholder.png'}
                  loading="lazy"
                  onLoad={() => setLoaded((state) => [...state, index])}
                  key={`${food.id}_${img?.id}`}
                />
              </ImageContainer>
              <p>{food.name}</p>
            </FoodItemContainer>
          )
        })}
      </Container>
      {data && data.foods.length >= PAGE_SIZE && (
        <LoadMoreButton
          onClick={() =>
            fetchMore({
              variables: {
                offset: data.foods.length,
              },
            })
          }
        >
          Načíst další
        </LoadMoreButton>
      )}
    </>
  )
}

export default OfferSection

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 530px) {
    flex-direction: row;
    margin: 20px 0;
  }
`

const FoodItemContainer = styled.div`
  flex: 1;
  max-width: 420px;
  text-align: center;
  cursor: pointer;
  margin: 20px 10px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  font-weight: bold;
  transition: 0.5s;
  padding: 20px 25px;
  box-sizing: border-box;

  p {
    margin: 0;
  }

  &:hover {
    box-shadow: 0rem 0.5rem 1.5rem rgb(0 0 0 / 30%);
    //transform: scale(1.01);
  }

  @media (min-width: 530px) {
    flex: 1 0 420px;
  }
`

const Image = styled.img`
  object-fit: cover;
  height: 200px;
  flex-basis: min-content;
  max-width: 420px;
  width: 100%;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;

  @media (min-width: 530px) {
    height: 300px;
  }
`

const ImageContainer = styled.div`
  margin-bottom: 15px;
  position: relative;
`

const LoaderContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
`

const SearchFood = styled(Search)`
  margin: 0;
`

const LoadMoreButton = styled(ExpandButton)``
