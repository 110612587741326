import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { useParams, useNavigate } from 'react-router'
import Select from 'react-select'
import { Checkbox, FormControlLabel } from '@mui/material'

import { UserAdminLevel } from 'src/constants/constants'
import { routes } from 'src/constants/routes'
import canteens from 'src/queries/v2/canteens'
import Button from 'src/components/Button'

import TextInput from '../../layout/textInput'
import useLogin from '../../hooks/useLogin'
import user from '../../queries/v2/user'
import updateUser from '../../mutations/v2/editUser'
import LoaderSpinner from '../../layout/loader'
import { InputValueStateType } from '../../constants/types'
import { navy } from 'src/layout/colors'

interface Props {
  userId: number
}

export const customStyles = {
  control: () => ({
    margin: ' 20px 0',
    border: '2px solid transparent',
    outline: 'none',
    padding: '5px',
    backgroundColor: '#F5F5F5',
    borderRadius: '5px',
    boxShadow: ' 0rem 0.5rem 2rem rgb(0 0 0 / 20%)',
    display: 'flex',
    fontSize: '13.3333px',
  }),
  menu: (provided: any) => ({
    ...provided,
    myrginTop: '-20px',
    backgroundColor: '#F5F5F5',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#005471' : '#F5F5F5',
    color: state.isFocused ? '#F5F5F5' : '#005471',
  }),
}

const Main = ({ userId }: Props) => {
  const loginData = useLogin()
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()

  if (id) {
    userId = parseInt(id)
  }

  const options = [
    { value: UserAdminLevel.INACTIVE_USER, label: 'Ban' },
    { value: UserAdminLevel.USER, label: 'Uživatel' },
    { value: UserAdminLevel.ADMIN, label: 'Správce jídelny' },
    { value: UserAdminLevel.SUPERADMIN, label: 'Admin' },
  ]

  const [submitButtonInactive, setSubmitButtonInactive] = useState(true)
  const [error, setError] = useState(false)
  const [formState, setFormState] = useState<
    InputValueStateType<
      | 'email'
      | 'password'
      | 'passwordAgain'
      | 'userName'
      | 'rights'
      | 'canteenId'
      | 'isEmailVerified'
    >
  >({
    email: { value: '', isInvalid: true },
    isEmailVerified: { value: 'false', isInvalid: true },
    password: { value: '', isInvalid: true },
    passwordAgain: { value: '', isInvalid: true },
    userName: { value: '', isInvalid: true },
    rights: { value: '', isInvalid: true },
    canteenId: { value: '', isInvalid: true },
  })

  const refetchQueries = {
    refetchQueries: [
      {
        query: user,
        variables: { userId },
      },
    ],
  }
  const [editCurrentUser] = useMutation(updateUser, {
    ...refetchQueries,
  })

  const canEditRoles = loginData.user?.adminLevel === UserAdminLevel.SUPERADMIN

  async function handleSubmit() {
    if (
      formState.userName.isInvalid ||
      formState.email.isInvalid ||
      formState.password.isInvalid ||
      formState.passwordAgain.isInvalid ||
      formState.password.value !== formState.passwordAgain.value
    ) {
      return setError(true)
    }
    const data = {
      id: userId,
      userName: formState.userName.value,
      password: formState.password.value || undefined,
      email: formState.email.value,
      isEmailVerified: canEditRoles ? formState.isEmailVerified.value === 'true' : undefined,
      adminLevel: canEditRoles ? parseInt(formState.rights.value) : undefined,
      canteenId: canEditRoles ? parseInt(formState.canteenId.value) : undefined,
    }
    setError(false)
    await editCurrentUser({ variables: { data } })
    navigate(routes.users)
    return true
  }

  const getActualUserData = useQuery(user, {
    variables: { userId },
  })

  const canteensOptions = useQuery(canteens)

  useEffect(() => {
    setFormState({
      email: {
        value: getActualUserData.loading ? '' : getActualUserData?.data?.user.email ?? '',
        isInvalid: false,
      },
      isEmailVerified: {
        value: getActualUserData.loading
          ? 'false'
          : getActualUserData?.data?.user.isEmailVerified
            ? 'true'
            : 'false',
        isInvalid: false,
      },
      password: { value: '', isInvalid: false },
      passwordAgain: { value: '', isInvalid: false },
      userName: {
        value: getActualUserData.loading ? '' : getActualUserData?.data?.user.userName ?? '',
        isInvalid: false,
      },
      rights: {
        value: getActualUserData.loading
          ? ''
          : getActualUserData?.data?.user.adminLevel.toString() ?? '',
        isInvalid: false,
      },
      canteenId: {
        value: getActualUserData.loading
          ? ''
          : getActualUserData?.data?.user.canteen?.id.toString() ?? '',
        isInvalid: false,
      },
    })
    setSubmitButtonInactive(true)
  }, [getActualUserData.data])

  useEffect(() => {
    if (formState.password.value !== formState.passwordAgain.value) {
      return setError(true)
    }
    return setError(false)
  }, [formState.passwordAgain.value, formState.password.value])

  useEffect(() => {
    if (
      formState.userName.isInvalid ||
      formState.email.isInvalid ||
      formState.password.isInvalid ||
      formState.passwordAgain.isInvalid ||
      formState.password.value !== formState.passwordAgain.value
    ) {
      setSubmitButtonInactive(true)
    } else {
      setSubmitButtonInactive(false)
    }
  }, [formState])

  function handleValidation(value: string, type: string) {
    if (type === 'password') {
      return value ? !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value) : false
    }
    if (type === 'text') {
      return !/^[a-zá-žA-ZÁ-Ž0-9._-]+$/.test(value)
    }
    if (type === 'email') {
      return !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value,
      )
    }
    return false
  }

  if (getActualUserData.loading) {
    return <LoaderSpinner />
  }

  const optionsCanteens =
    canteensOptions.data?.canteens?.map((canteen) => ({
      value: canteen.id.toString(),
      label: canteen.name,
    })) ?? []

  return (
    <>
      <Container100vh>
        <Container>
          <LoginForm>
            <Heading>
              Úprava uživatele
              <br />
              <br />
              {getActualUserData?.data?.user.userName}
            </Heading>
            <TextInput
              type="text"
              placeholder="Uživatelské jméno"
              value={formState.userName.value}
              isInvalid={formState.userName.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  userName: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value, e.target.type),
                  },
                })
              }
            />

            <TextInput
              type="email"
              placeholder="Váš email"
              name="email"
              value={formState.email.value}
              isInvalid={formState.email.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  email: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value, e.target.type),
                  },
                })
              }
            />
            {canEditRoles && (
              <>
                <FormControlLabel
                  value={formState.isEmailVerified.value === 'true'}
                  checked={formState.isEmailVerified.value === 'true'}
                  onChange={(e) => {
                    const isChecked = (e.target as any).checked
                    setFormState({
                      ...formState,
                      isEmailVerified: {
                        value: isChecked ? 'true' : 'false',
                        isInvalid: false,
                      },
                    })
                  }}
                  control={<Checkbox />}
                  label="Email je ověřen"
                />

                <p>Změna role:</p>
                <Select
                  isSearchable={false}
                  styles={customStyles}
                  options={options}
                  value={options.find((obj) => obj.value === parseInt(formState.rights.value))}
                  //isInvalid={formState.rights.isInvalid}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      rights: {
                        value: e ? e.value.toString() : '1',
                        isInvalid: handleValidation(e ? e.value.toString() : '1', 'select'),
                      },
                    })
                  }
                />
                {(formState.rights.value === '1' || formState.rights.value === '2') && (
                  <Select
                    isSearchable={false}
                    styles={customStyles}
                    options={optionsCanteens}
                    value={optionsCanteens.find((obj) => obj.value === formState.canteenId.value)}
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        canteenId: {
                          value: e ? e.value.toString() : '1',
                          isInvalid: handleValidation(e ? e.value.toString() : '1', 'select'),
                        },
                      })
                    }
                    placeholder={'Vyberte jídelnu'}
                  />
                )}
              </>
            )}
            <p>Změna hesla:</p>
            <TextInput
              type="password"
              placeholder="Nové heslo"
              autoComplete="new-password"
              value={formState.password.value}
              isInvalid={formState.password.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  password: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value, e.target.type),
                  },
                })
              }
            />
            <TextInput
              type="password"
              placeholder="Nové heslo znovu"
              autoComplete="new-password"
              value={formState.passwordAgain.value}
              isInvalid={formState.passwordAgain.isInvalid}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  passwordAgain: {
                    value: e.target.value,
                    isInvalid: handleValidation(e.target.value, e.target.type),
                  },
                })
              }
            />
            <div>{error && <LoginError>Hesla se neshodují</LoginError>}</div>
            <br />
            <Button disabled={submitButtonInactive} onClick={handleSubmit}>
              Provést změny
            </Button>
          </LoginForm>
        </Container>
      </Container100vh>
    </>
  )
}

export default Main

const Container100vh = styled.div`
  min-height: calc(100vh - 208.88px);

  display: flex;
  justify-content: center;
  align-items: center;

  background: url('/img/pattern_modry.svg'), ${navy};
  background-attachment: fixed;
  background-position: center;
  background-size: 730px;

  @media (min-width: 366px) {
    min-height: calc(100vh - 219.88px);
  }
`

const Container = styled.div`
  flex: 1;
  padding: 30px 0;
  height: 100%;
`

const LoginForm = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 50px 20px;
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  background-color: white;

  @media (min-width: 500px) {
    padding: 20px 50px 50px 50px;
    width: 70%;
  }

  @media (min-width: 600px) {
    width: 50%;
  }

  @media (min-width: 900px) {
    width: 30%;
  }

  @media (min-width: 2000px) {
    width: 20%;
  }

  @media (min-width: 3000px) {
    width: 15%;
  }
`

const Heading = styled.h2`
  text-align: center;
`

const LoginError = styled.span`
  color: #fc6969;
  font-weight: 500;
  font-size: 0.81em;
  position: absolute;
  margin-bottom: 0.75em;
  margin-top: -0.8em;
  display: flex;
  justify-content: flex-start;
`
