import React from 'react'
import styled from 'styled-components'
import {
  Person as PersonIcon,
  Delete as DeleteIcon,
  AccessTime as AccessTimeIcon,
} from '@mui/icons-material'
import { useMutation } from '@apollo/client'
import { Stack } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'

import { navy } from '../../layout/colors'
import removeComment from '../../mutations/v2/removeComment'
import comments, { Comment as CommentType } from '../../queries/v2/comments'

interface Props {
  comment: CommentType
  foodId: number | undefined
  canteenId: number | undefined
  userCanEditComment: boolean
  showFoodName?: boolean
}

export const getStars = (stars: number | null | undefined, maxStars?: number, size?: number) => {
  if (stars === null || stars === undefined) {
    return '---'
  }

  return [...Array(maxStars ?? 5)].map((val, i) => (
    <svg
      fill="currentColor"
      width={size ?? 16}
      height={size ?? 16}
      viewBox="0 0 24 24"
      className="star-svg"
    >
      <path
        fill={stars > i ? 'rgb(255, 188, 11)' : 'rgb(204, 204, 204)'}
        stroke="none"
        strokeMiterlimit="10"
        strokeWidth="0"
        d="M12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27z"
      />
    </svg>
  ))
}

const Comment = ({ comment, foodId, canteenId, userCanEditComment, showFoodName }: Props) => {
  const confirm = useConfirm()
  const date = new Date(comment.date)

  const username = comment.user?.userName
    ? comment.user?.userName.length > 11 && comment.user.userName != 'Uživatel byl smazán'
      ? `${comment.user.userName.substring(0, 9)}...`
      : comment.user.userName
    : ''

  const refetchQueries = {
    refetchQueries: [
      {
        query: comments,
        variables: { query: { foodId, canteenId } },
      },
    ],
  }
  const [req] = useMutation(removeComment, {
    ...refetchQueries,
  })

  function handleDelete(commentId: number) {
    confirm({
      title: `Smazat komentář?`,
      description: `Tato akce je nevratná.`,
      cancellationText: 'Zrušit',
    }).then(() => {
      req({ variables: { id: commentId } })
    })
  }

  return (
    <Container>
      {showFoodName && <h3>{comment.food?.name}</h3>}
      {comment.text && <p>{comment.text}</p>}
      <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={1}>
        <RatingWithLabel>
          <span>Chuť:</span>
          <span>{getStars(comment.ratingTaste ?? 0)}</span>
        </RatingWithLabel>
        <RatingWithLabel>
          <span>Vzhled:</span>
          <span>{getStars(comment.ratingVisual ?? 0)}</span>
        </RatingWithLabel>
        <RatingWithLabel>
          <span>Příprava:</span>
          <span>{getStars(comment.ratingPreparation ?? 0)}</span>
        </RatingWithLabel>
      </Stack>
      <CommentData>
        <Text>
          <Person />
          {username}
        </Text>
        <Text>
          <TimeIcon />
          {date.getDate()}.{date.getMonth() + 1}.{date.getFullYear()}
          {userCanEditComment && (
            <Delete
              onClick={() => {
                handleDelete(comment.id)
              }}
            />
          )}
        </Text>
      </CommentData>
    </Container>
  )
}

export default Comment

const Container = styled.div`
  box-shadow: 0rem 0.5rem 2rem rgb(0 0 0 / 20%);
  border-radius: 5px;
  margin: 25px 0;
  padding: 15px;
`

const CommentData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: gray;
  flex-direction: column;

  @media (min-width: 500px) {
    flex-direction: row;
  }
`

const Text = styled.p`
  display: inline-flex;
  align-items: center;
`

const Person = styled(PersonIcon)`
  margin-right: 5px;
`

const TimeIcon = styled(AccessTimeIcon)`
  margin-right: 5px;
`

const Delete = styled(DeleteIcon)`
  color: ${navy};
  cursor: pointer;
`

const RatingWithLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  span:last-child {
    margin-top: 4px;
  }
`
