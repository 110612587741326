import React from 'react'
import styled from 'styled-components'
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  AddCircle as AddCircleIcon,
  Refresh as RefreshIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material'

import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router'
import { useConfirm } from 'material-ui-confirm'

import { backgroundGray, navy } from '../../layout/colors'
import removeCanteen from '../../mutations/v2/removeCanteen'
import canteens, { Canteen } from '../../queries/v2/canteens'
import { routes } from 'src/constants/routes'
import refreshCanteen from 'src/mutations/v2/refreshCanteen'
import { IconButton } from 'src/components/Button'

interface Props {
  item: Canteen
}

const Main = ({ item }: Props) => {
  const confirm = useConfirm()
  const navigate = useNavigate()

  const refetchQueries = { refetchQueries: [{ query: canteens }] }
  const [req] = useMutation(removeCanteen, {
    ...refetchQueries,
  })

  const [reqRefresh] = useMutation(refreshCanteen, {
    ...refetchQueries,
    onCompleted() {
      navigate(routes.canteenDetail(item.id))
    },
  })

  function handleDelete() {
    confirm({
      title: `Smazat ${item.name}?`,
      description: `Tato akce je nevratná.`,
      cancellationText: 'Zrušit',
    }).then(() => req({ variables: { id: item.id } }))
  }

  function handleRefresh() {
    reqRefresh({ variables: { canteenId: item.id } })
  }

  return (
    <Row>
      <Td>{item.name}</Td>
      <Td>{item.region}</Td>
      <Td>{item.city}</Td>
      <Td>{item.canteenSystemType}</Td>
      <Td>
        <IconButton onClick={() => navigate(routes.canteenDetail(item.id))}>
          <Visibility />
        </IconButton>
      </Td>
      <Td title="Načíst jídelníček">
        <IconButton onClick={() => handleRefresh()}>
          <RefreshIconNavy />
        </IconButton>
      </Td>
      <Td>
        <IconButton onClick={() => navigate(routes.canteenDetailFoodAdd(item.id))}>
          <AddIcon />
        </IconButton>
      </Td>
      <Td>
        <IconButton onClick={() => navigate(routes.canteenEdit(item.id))}>
          <Edit />
        </IconButton>
      </Td>
      <Td>
        <IconButton
          onClick={() => {
            handleDelete()
          }}
        >
          <Delete />
        </IconButton>
      </Td>
    </Row>
  )
}

export default Main

const Row = styled.tr`
  &:nth-child(odd) {
    background-color: ${backgroundGray};
  }

  &:hover {
    background-color: #e9e9e9;
  }
`

const Td = styled.td`
  padding: 5px;

  &:nth-last-child(1) {
    text-align: right;
    width: 34px;
  }

  &:nth-last-child(2) {
    text-align: right;
    width: 34px;
  }

  &:nth-last-child(3) {
    text-align: right;
    width: 34px;
  }

  &:nth-last-child(4) {
    text-align: right;
    width: 34px;
  }

  &:nth-last-child(5) {
    text-align: right;
  }
`

const Edit = styled(EditIcon)`
  color: ${navy};
`

const Delete = styled(DeleteIcon)`
  color: ${navy};
`

const AddIcon = styled(AddCircleIcon)`
  color: ${navy};
`

const RefreshIconNavy = styled(RefreshIcon)`
  color: ${navy};
`

const Visibility = styled(VisibilityIcon)`
  color: ${navy};
`
